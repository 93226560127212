<template>
    <div
        class="w-100 ndx-form-element ndx-select"
        :class="{[variant]: true, error: invalid, disabled: readonly || printView}"
    >
        <div v-if="label && label.length" class="label">{{ label }}</div>
        <div class="dropdown">
            <div
                v-if="availableBudgets.length"
                class="dropdown-toggle d-flex justify-content-between align-items-center"
                :id="dropdownId"
                data-bs-toggle="dropdown"
                :disabled="readonly || printView"
                aria-expanded="false"
                data-bs-boundary="body"
                data-bs-display="dynamic"
            >
                <template v-if="usedBudget && !minimalView">
                    <div class="budgetInfo">
                        {{
                            $t(
                                'message.budgetAvailable',
                                {budgetName: usedBudget.name, value: formatPrice(usedBudget.remainingValue)}
                            )
                        }}
                        <div class="highlight">
                            <template v-if="!hasRemainingValue(usedBudget)">
                                {{ $t('message.budgetToLow') }}
                            </template>
                            <template v-else>
                                {{ $t('message.budgetCharge', {value: formattedCosts}) }}
                                <template v-if="showAdditionalShippingCostsInfo">
                                    <br/>{{ $t('label.plus_delivery') }}
                                </template>
                            </template>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="selectedValue">
                        <template v-if="usedBudget && minimalView">
                            {{ usedBudget.name }}
                        </template>
                        <template v-else-if="selectedBudget === -1 || (!selectedBudget && !supportNoBudgetUse)">
                            {{ $t('label.select_make_choice') }}
                        </template>
                        <template v-else>{{ $t('label.ownCosts') }}</template>
                    </div>
                </template>
                <NdxIcon icon="drop-down" size="s"/>
            </div>
            <ul
                class="dropdown-menu dropdown-menu-end"
                :aria-labelledby="dropdownId"
            >
                <li
                    class="w-100 px-3 pb-3"
                    v-if="availableBudgets.length + 1 >= searchFieldLength"
                >
                    <NdxSearchInput
                        class="w-100"
                        v-model="searchText"
                    />
                </li>
                <li
                    class="dropdown-item"
                    :class="{active: !usedBudget && selectedBudget !== -1}"
                    v-if="supportNoBudgetUse && matchesSearchText($t('label.ownCosts'))"
                    @click="usedBudget !== null ? setBudget(null) : () => {}"
                >
                    {{ $t('label.ownCosts') }}
                </li>
                <template v-for="budget in availableBudgets" :key="budget.id">
                    <li
                        v-show="matchesSearchText(budget.name)"
                        class="budgetInfo dropdown-item"
                        :class="{
                            active: budget.id === usedBudget?.id,
                            disabled: !hasRemainingValue(budget)
                        }"
                        @click="!hasRemainingValue(budget) || budget.id === usedBudget?.id
                            ? () => {} : setBudget(budget.id)"
                    >
                        {{
                            $t(
                                'message.budgetAvailable',
                                {budgetName: budget.name, value: formatPrice(budget.remainingValue)}
                            )
                        }}
                        <div class="note">
                            <template v-if="!hasRemainingValue(budget)">
                                {{ $t('message.budgetToLow') }}
                            </template>
                            <template v-else-if="!minimalView">
                                {{ $t('message.budgetCharge', {value: formattedCosts}) }}
                                <template v-if="showAdditionalShippingCostsInfo">
                                    <br/>{{ $t('label.plus_delivery') }}
                                </template>
                            </template>
                        </div>
                    </li>
                </template>
            </ul>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapState } from "vuex";
    import NdxIcon from "../../library/NdxIcon";
    import { PriceFormatter } from "../../../plugins/formatter";
    import NdxSearchInput from "../../library/formElements/NdxSearchInput.vue";

    export default {
        name: "BudgetSelect",
        components: {NdxSearchInput, NdxIcon},
        emits: ['change-budget'],
        props: {
            costs: {
                type: Number,
                required: true
            },
            availableBudgets: {
                type: Array,
                required: true
            },
            selectedBudget: {
                type: [Number, null],
                required: true
            },
            readonly: {
                type: Boolean
            },
            searchFieldLength: {
                type: Number,
                default: 10
            },
            label: {
                type: String
            },
            variant: {
                type: String,
                default: 'secondary',
                validator: function (value) {
                    return ['primary', 'secondary', 'outline', 'noStyle'].indexOf(value) !== -1;
                }
            },
            minimalView: {
                type: Boolean
            },
            invalid: {
                type: Boolean
            },
            showAdditionalShippingCostsInfo: {
                type: Boolean
            },
            ignoreRemainingValue: {
                type: Boolean
            }
        },
        data() {
            return {
                dropdownId: 'dropdownMenuButton_' + this.ndxUuid,
                searchText: '',
            };
        },
        computed: {
            ...mapGetters('shop', [
                'currency'
            ]),
            ...mapState({
                printView: state => state.basket.printView
            }),
            usedBudget() {
                return this.availableBudgets.find(budget => budget.id === this.selectedBudget);
            },
            supportNoBudgetUse() {
                return this.availableBudgets.reduce((prevVal, budget) => prevVal || !budget.restrictedProducts, false);
            },
            formattedCosts() {
                return this.formatPrice(this.costs);
            }
        },
        mounted() {
            let dropDownEl = document.getElementById(this.dropdownId);
            if (dropDownEl) {
                let me = this;
                dropDownEl.addEventListener('hidden.bs.dropdown', function () {
                    me.searchText = '';
                });
            }
        },
        methods: {
            setBudget(budgetId) {
                this.$emit('change-budget', budgetId);
            },
            formatPrice(value) {
                return PriceFormatter(value, this.currency, this);
            },
            matchesSearchText(text) {
                return text.toLowerCase().includes((this.searchText || '').toLowerCase());
            },
            hasRemainingValue(budget) {
                return this.ignoreRemainingValue || budget.remainingValue >= this.costs;
            }
        }
    };
</script>

<style scoped lang="scss">
    @import '../../../style/variables_ndx';

    .ndx-select {
        :deep(.selectedValue) {
            white-space: nowrap;
        }
    }

    .dropdown-toggle,
    .dropdown-item {
        white-space: normal;
    }

    .budgetInfo {
        @extend %font-body2;
        padding-top: 4px;
        padding-bottom: 4px;

        .highlight {
            @extend %font-caption;
            color: var(--bs-danger);
        }

        .note {
            @extend %font-caption;
        }

        &.active {
            .note {
                color: var(--bs-gray-middle);
            }
        }
    }
</style>
