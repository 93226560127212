<template>
    <div
        v-if="printView"
        class="d-flex justify-content-between d-print-none pt-3 pb-3"
    >
        <NdxButton @click="disablePrintView">{{ $t('btn.disablePrintView') }}</NdxButton>
        <NdxButton @click="print">{{ $t('btn.openPrintDialog') }}</NdxButton>
    </div>
    <div
        v-if="basket && !loading"
        class="basket-frame"
    >
        <div class="frameContainer">
            <template v-if="printView">
                <div v-html="contentBlocks.header"></div>
                <table class="printViewTbl roundedBorder w-100">
                    <tbody>
                        <tr>
                            <td class="border-right">
                                <div class="list-key-table">
                                    <div class="listKey">{{ $t('label.date') }}</div>
                                    <div class="listValue">{{ $d(new Date(), 'short') }}</div>

                                    <div class="listKey">{{ $t('label.client') }}</div>
                                    <div class="listValue">{{ $store.getters['user/shopClientName'] }}</div>

                                    <div class="listKey">{{ $t('label.user') }}</div>
                                    <div class="listValue">{{ $store.getters['user/userFullname'] }}</div>

                                    <div class="listKey">{{ $t('label.email') }}</div>
                                    <div class="listValue">{{ $store.getters['user/userEmail'] }}</div>
                                </div>
                            </td>
                            <td>
                                <SummaryWidget/>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </template>

            <NdxPageHeader
                class="hideOnPrintView"
                :sticky="bulkModeActive"
                :breadcrumbs="breadcrumbs"
                :active-route-callback="activeBundle ? clearFilter : null"
                :nav-name="basketMenuItem.label"
                :view-mode-options="viewModeOptions"
                :view-mode-storage-key="VIEW_MODE_STORAGE_KEY"
                v-model:view-mode="viewMode"
                :active-filter="activeBundle !== null"
                :filter-btn-disabled="!(bundles?.length) || viewMode !== VIEW_MODE_VENDORS"
                v-model:show-filter="showFilter"
            >
                <NdxButton
                    v-if="bulkModeActive"
                    variant="primary"
                    @click.stop="prepareBulkOperation('removeBundle')"
                    :disabled="!hasSelected"
                >{{ $t('btn.delete') + selectionCounter}}</NdxButton>
                <NdxButton
                    v-if="bulkModeActive"
                    variant="primary"
                    @click.stop="prepareBulkOperation('moveBundleToWatchlist')"
                    :disabled="!hasSelected"
                >{{ $t('btn.pushToWatchlist') + selectionCounter}}</NdxButton>
                <NdxButton
                    variant="secondary"
                    @click="switchBulkMode"
                >{{ bulkModeActive ? $t('btn.cancel') : $t('btn.select') }}
                </NdxButton>
            </NdxPageHeader>
            <NdxFlyIn v-if="showFilter" class="limit500">
                <template #title>
                    {{ $t('label.filterHeadline') }}
                </template>
                <template #default>
                    <BundleFilter v-if="bundles?.length" type="basket" :first-level-bundles="bundles"/>
                </template>
                <template #buttons>
                    <NdxButtonLink
                        class="btnFlex"
                        @click="clearFilter"
                        :disabled="activeBundle === null"
                    >
                        {{ $t('btn.reset') }}
                    </NdxButtonLink>
                    <NdxButton class="btnFlex" @click="showFilter = false">
                        {{ $t('btn.close') }}
                    </NdxButton>
                </template>
            </NdxFlyIn>

            <BasketSummary
                v-if="!printView"
                class="d-block d-xl-none mt-2 mb-4"
            />

            <div
                v-if="viewMode === VIEW_MODE_VENDORS && filteredGroups?.length"
                class="groupContainer"
            >
                <BasketVendorGroup
                    v-for="group in filteredGroups"
                    :key="group.vendor.id"
                    :group="group"
                    :bulk-mode-active="bulkModeActive"
                    :selected-bundles="selected"
                    @select-bundle="selectBundle"
                    @refresh-basket="getBasket"
                />
            </div>
            <div
                v-else-if="viewMode === VIEW_MODE_BUDGETS && filteredBudgetList?.length"
                class="groupContainer"
            >
                <BasketBudgetGroup
                    v-for="group of filteredBudgetList"
                    :key="group.budget ? group.budget.id : 'noBudget'"
                    :group="group"
                    :order-items="getOrderItems"
                    :bulk-mode-active="bulkModeActive"
                    :selected-bundles="selected"
                    @select-bundle="selectBundle"
                    @refresh-basket="getBasket"
                />
            </div>
            <div
                v-else-if="viewMode === VIEW_MODE_PRODUCTS && filteredList?.length"
                class="groupContainer"
            >
                <BasketProductGroup
                    :bundles="filteredList"
                    :bulk-mode-active="bulkModeActive"
                    :selected-bundles="selected"
                    @select-bundle="selectBundle"
                    @refresh-basket="getBasket"
                />
            </div>
            <div v-else>
                <NdxNotification
                    variant="info"
                    :model-value="true"
                    :duration="0"
                    :message="$t('message.basketIsEmpty')"
                />
            </div>
            <div v-if="printView" v-html="contentBlocks.footer"></div>
        </div>
    </div>
    <div v-else>
        <NdxNotification
            variant="info"
            :model-value="true"
            :duration="0"
            :message="$t('message.basketCalculationRunning')"
        />
    </div>

    <NdxFlyIn v-if="showBulkDeleteDialog">
        <template #title>
            {{ $t('label.confirmationRequired')}}
        </template>
        <template #default>
            {{ $t(bulkOperation === 'removeBundle'?'message.bulkDelete':'message.bulkMove', {count: selected.length}) }}
            <br/>
            <br/>
            <br/>
            <NdxProgress v-if="showProgress" :current="currentProgress" :max="maxProgress" />
        </template>
        <template #buttons>
            <NdxButtonLink
                class="btnFlex"
                @click="hideBulkDeleteDialog">
                {{ $t('btn.cancel') }}
            </NdxButtonLink>
            <NdxButton
                class="btnFlex"
                @click="bulkDelete">
                {{ $t(bulkOperation === 'removeBundle'?'btn.delete':'btn.pushToWatchlist') }}
            </NdxButton>
        </template>
    </NdxFlyIn>

    <BasketQuickCheckout/>
</template>

<script>
    import { mapGetters, mapState } from "vuex";
    import BasketVendorGroup from "./parts/BasketVendorGroup";
    import BasketQuickCheckout from "./BasketQuickCheckout";
    import SummaryWidget from "../checkout/steps/parts/SummaryWidget";
    import BundleFilter from "../checkout/steps/parts/BundleFilter.vue";
    import NdxNotification from "../library/NdxNotification";
    import NdxButton from "../library/NdxButton";
    import NdxFlyIn from "../library/NdxFlyIn.vue";
    import NdxButtonLink from "../library/NdxButtonLink.vue";
    import BasketSummary from "./parts/BasketSummary.vue";
    import NdxPageHeader from "../library/NdxPageHeader.vue";
    import BulkDelete from "@mixins/BulkDelete.vue";
    import NdxProgress from "../library/NdxProgress.vue";
    import BasketBudgetGroup from "./parts/BasketBudgetGroup.vue";
    import BasketProductGroup from "./parts/BasketProductGroup.vue";

    export default {
        name: "BasketFrame",
        components: {
            BasketProductGroup,
            NdxProgress, BasketBudgetGroup, NdxPageHeader, BasketSummary,
            NdxNotification, BasketVendorGroup, NdxButtonLink, NdxFlyIn, BundleFilter, BasketQuickCheckout,
            SummaryWidget, NdxButton
        },
        mixins: [BulkDelete],
        data() {
            return {
                loading: false,
                showFilter: false,
                bulkModeActive: false,
                bulkOperation: null,

                VIEW_MODE_STORAGE_KEY: 'basketViewMode',
                VIEW_MODE_VENDORS: 'vendors',
                VIEW_MODE_PRODUCTS: 'products',
                VIEW_MODE_BUDGETS: 'budgets',
                viewMode: 'vendors'
            };
        },
        computed: {
            ...mapGetters('basket', {
                getOrderItem: 'getOrderItem',
                getOrderItems: 'getOrderItems',
                hasPrintView: 'hasPrintView',
                productCount: 'getProductCount'
            }),
            ...mapState({
                bundleBreadcrumbs: state => state.bundles.breadcrumb,
                activeBundle: state => state.bundles.activeBundle,
                printView: state => state.basket.printView,
                contentBlocks: state => state.basket.contentBlocks
            }),
            showPrintViewBtn() {
                return this.hasPrintView && this.productCount > 0;
            },
            basket() {
                return this.$store.getters['basket/getBasket'];
            },
            basketMenuItem() {
                return this.$store.getters['shop/getWgcByType']('basket');
            },
            bundles() {
                // we must use bundleTrees from basket here
                if (this.basket.bundleTrees) {
                    return this.basket.bundleTrees.filter((tree) => {
                        return tree.bundleProduct !== null;
                    });
                }
                return [];
            },
            filteredGroups() {
                let _filteredGroups = [];

                const hasNode = (nodes, orderItemId) => {
                    let found = nodes.find((node) => {
                        if (node.orderItemId === orderItemId) {
                            return true;
                        }
                        if (node.bundleProduct) {
                            return hasNode(node.children, orderItemId);
                        }
                    });
                    return !!found;
                };
                const isItemVisible = (item) => {
                    if (this.activeBundle === null || !this.activeBundle.children) {
                        return true;
                    }
                    return hasNode(this.activeBundle.children, item.orderItemId);
                };

                this.basket.groups.forEach((group) => {
                    let filteredItems = group.items.filter((item) => {
                        return isItemVisible(item);
                    });
                    if (filteredItems.length) {
                        _filteredGroups.push({
                            items: filteredItems,
                            summary: group.summary,
                            vendor: group.vendor
                        });
                    }
                });
                return _filteredGroups;
            },
            filteredList() {
                return this.basket.bundleTrees.map((tree) => {
                    return {
                        bundleId: tree.bundleId,
                        children: tree.children,
                        orderItemId: tree.orderItemId,
                        bundleProduct: tree.bundleProduct,
                        orderItem: this.getOrderItem(tree.orderItemId)
                    };
                }).filter(() => {
                    // add vendor filter
                    return true;
                });
            },
            filteredBudgetList() {
                let list = {};
                let noBudget = {
                    budget: null,
                    items: []
                };
                this.getOrderItems.forEach((orderItem) => {
                    if (!orderItem.budget) {
                        noBudget.items.push(orderItem);
                    } else {
                        const key = 'budget_' + orderItem.budget.id;
                        if (!(key in list)) {
                            list[key] = {
                                budget: orderItem.budget,
                                items: []
                            };
                        }
                        list[key].items.push(orderItem);
                    }
                });

                list = Object.values(list);

                if (noBudget.items.length) {
                    noBudget.items = noBudget.items.sort((a, b) => {
                        // orderItems with availableBudgets should come first
                        if (a.availableBudgets.length > 0 && !b.availableBudgets.length > 0) {
                            return -1;
                        }
                        if (b.availableBudgets.length > 0 && !a.availableBudgets.length) {
                            return 1;
                        }
                        // don't change order
                        return 0;
                    });
                    list.push(noBudget);
                }

                return list;
            },
            breadcrumbs() {
                let list = [];
                if (this.bundleBreadcrumbs) {
                    for (let item of this.bundleBreadcrumbs) {
                        let click = () => {
                            const path = this.$store.getters['bundles/getPathFromBundleId'](item.bundleId);
                            this.$store.dispatch('bundles/setPath', path);
                        };
                        if (this.activeBundle && this.activeBundle.bundleId === item.bundleId) {
                            click = null;
                        }
                        list.push({
                            label: item.bundleProduct.name,
                            click: click
                        });
                    }
                }
                return list;
            },
            viewModeOptions() {
                let options = [{
                    highlighted: this.viewMode === this.VIEW_MODE_VENDORS,
                    value: this.VIEW_MODE_VENDORS,
                    text: this.$t('label.vendors'),
                    click: () => this.setViewMode(this.VIEW_MODE_VENDORS)
                }, {
                    highlighted: this.viewMode === this.VIEW_MODE_PRODUCTS,
                    value: this.VIEW_MODE_PRODUCTS,
                    text: this.$t('label.product'),
                    click: () => this.setViewMode(this.VIEW_MODE_PRODUCTS)
                }];

                const budgetWgc = this.$store.getters['shop/getWgcByType']('budgets');
                if (budgetWgc) {
                    options.push({
                        highlighted: this.viewMode === this.VIEW_MODE_BUDGETS,
                        value: this.VIEW_MODE_BUDGETS,
                        text: budgetWgc.label,
                        click: () => this.setViewMode(this.VIEW_MODE_BUDGETS)
                    });
                }

                if (this.showPrintViewBtn) {
                    options.push({
                        divider: true,
                        text: this.$t('btn.openPrintView'),
                        click: () => this.generatePrintView()
                    });
                }

                return options;
            }
        },
        watch: {
            printView: function (isPrintView) {
                if (isPrintView) {
                    document.body.classList.add('printView');
                } else {
                    document.body.classList.remove('printView');
                }
            },
            bundles: {
                immediate: true,
                handler: function (trees) {
                    this.$store.dispatch('bundles/setBundleTrees', trees);
                }
            }
        },
        created() {
            this.getBasket();
            this.$store.dispatch('bundles/resetBundles');
        },
        mounted() {
            this.$store.dispatch('basket/showBasketSummary', true);
        },
        unmounted() {
            this.$store.dispatch('basket/resetQuickCheckoutData');
            this.$store.dispatch('bundles/resetBundles');
        },
        methods: {
            switchBulkMode() {
                this.bulkModeActive = !this.bulkModeActive;
                if (!this.bulkModeActive) {
                    this.selected = [];
                }
                this.$store.dispatch('basket/showBasketSummary', !this.bulkModeActive);
            },
            prepareBulkOperation(method) {
                this.onBulkDelete();
                this.bulkOperation = method;
                this.removeItemAction = async function(bundleId) {
                    if (method === 'removeBundle') {
                        await this.$store.dispatch('basket/removeBundle', {
                            bundleId: bundleId, listType: 'basket', skipHydration: true});
                    }
                    if (method === 'moveBundleToWatchlist') {
                        await this.$store.dispatch('basket/moveBundleToWatchlist', {
                            bundleId: bundleId, skipHydration: true});
                    }
                };
            },
            postDeletionAction() {
                this.switchBulkMode();
                this.getBasket();
                this.$store.dispatch('bundles/resetBundles');
            },
            selectBundle(val, bundleId) {
                const index = this.selected.indexOf(bundleId);
                if (val && index === -1) {
                    this.selected.push(bundleId);
                }
                if (!val && index !== -1) {
                    this.selected.splice(index, 1);
                }
            },
            selectAll(val) {
                this.selected = [];
                if (val) {
                    this.filteredList.forEach(item => {
                        this.selected.push(item.bundleId);
                    });
                }
            },
            getBasket() {
                this.loading = true;
                this.$store.dispatch('basket/getBasket').finally(() => {
                    this.loading = false;
                });
            },
            print() {
                window.print();
            },
            disablePrintView() {
                this.$store.dispatch('basket/showPrintView', {
                    printView: false,
                    contentBlocks: {}
                });
            },
            clearFilter() {
                this.$store.dispatch('bundles/setPath', null);
                this.showFilter = false;
            },
            setViewMode(viewMode) {
                window.localStorage.setItem(this.VIEW_MODE_STORAGE_KEY, viewMode);
                this.viewMode = viewMode;
                this.clearFilter();
            },
            generatePrintView() {
                this.$store.dispatch('basket/getPrintContentBlocks').then((contentBlocks) => {
                    this.$store.dispatch('basket/showPrintView', {
                        contentBlocks,
                        printView: true
                    });
                });
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "../../style/variables_ndx";

    .printViewTbl {
        border-collapse: collapse;

        & > tbody > tr > td {
            padding: 32px;

            &.border-right {
                border-right: 1px solid var(--bs-gray-middle);
            }
        }
    }
</style>
