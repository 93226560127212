<template>
    <div class="roundedBorder">

        <div class="d-flex justify-content-between border-b gap-3">
            <div class="key">{{ $t('label.order_date') }}</div>
            <div class="value">{{ $d(order.orderDate, 'long') }}</div>
        </div>
        <div class="d-flex justify-content-between border-b gap-3">
            <div class="key">{{ $t('label.deliveryAddress') }}</div>
            <div v-if="isMultiAddressDefinition" class="text-end">
                <div class="value">{{ $t('message.multiAddressDelivery') }}</div>
            </div>
            <div
                v-else
                class="value"
            >
                <template v-if="hasDeliveryAddress">{{ formatAddress(checkoutData.deliveryAddress) }}</template>
            </div>
        </div>
        <div class="d-flex justify-content-between border-b gap-3">
            <div class="key">{{ $t('label.deliveryAddressComment') }}</div>
            <div
                v-if="hasAddressComments"
                class="value"
            >
                <div>
                    <template
                        v-for="(data, addressId) in addressComments"
                        :key="addressId"
                    >
                        <div v-if="data.comment?.length">
                            <div class="key disabled">{{ formatAddress(data.address) }}</div>
                            <div
                                class="d-inline-block value mb-2 text-end preserveLineBreaks"
                            >
                                {{ addressCommentBrs(data.comment) }}
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
        <div
            v-if="order.shipments && order.shipments.length > 0"
            class="d-flex justify-content-between border-b gap-3"
        >
            <div class="key">{{ $t('label.tracking') }}</div>
            <div class="value shipments">
                <Shipments :shipments="order.shipments"/>
            </div>
        </div>
        <div class="d-flex justify-content-between border-b gap-3">
            <div class="key">{{ $t('label.paymentMethod') }}</div>
            <div class="value">{{ $t('label.paymentOffline') }}</div>
        </div>
        <div class="d-flex justify-content-between gap-3">
            <div class="key">{{ $t('label.invoiceAddress') }}</div>
            <div
                v-if="isInvoiceAddressSameAsDeliveryAddress"
                class="value"
            >
                {{ $t('message.sameAsDeliveryAddress') }}
            </div>
            <div
                v-else
                class="value"
            >
                {{ formatAddress(checkoutData.invoiceAddress) }}
                <template v-if="!this.checkoutData?.invoiceAddress?.id && hasDeliveryAddress">
                    {{ $t('message.sameAsDeliveryAddress') }}
                </template>
            </div>
        </div>
    </div>
    <template v-if="order.checkoutValues?.length">
        <CheckoutCustomizationFields
            :groups="order.checkoutValues"
            is-order-view
            readonly
        />
    </template>
</template>

<script>
    import { mapGetters } from "vuex";
    import { AddressFormatter } from "../../../plugins/formatter";
    import { stripHtmlTags } from "@utilities/ndxText";
    import Shipments from "./Shipments";
    import CheckoutCustomizationFields from "../../checkout/steps/parts/CheckoutCustomizationFields";

    export default {
        name: 'OrderSummary',
        components: {CheckoutCustomizationFields, Shipments},
        props: {
            order: {
                type: Object,
                required: true
            }
        },
        computed: {
            ...mapGetters('orders', {
                isMultiAddressDelivery: 'isMultiAddressDelivery',
                getAddress: 'getAddress',
                checkoutData: 'getCheckoutData'
            }),
            isMultiAddressDefinition() {
                return this.isMultiAddressDelivery;
            },
            isInvoiceAddressSameAsDeliveryAddress() {
                return !this.isMultiAddressDefinition &&
                    this.checkoutData?.deliveryAddress?.id === this.checkoutData?.invoiceAddress?.id;
            },
            hasDeliveryAddress() {
                return this.checkoutData.deliveryAddress;
            },
            hasAddressComments() {
                if (this.addressComments) {
                    for (const i in this.addressComments) {
                        if (this.addressComments[i].comment?.length) {
                            return true;
                        }
                    }
                }
                return false;
            },
            addressComments() {
                const addressComments = {};

                this.order.deliveryContacts.forEach(deliveryContact => {
                    if (deliveryContact.address.comment) {
                        addressComments[deliveryContact.address.id] = {
                            address: this.getAddress(deliveryContact.address.id),
                            comment: deliveryContact.address.comment
                        };
                    }
                });

                return addressComments;
            }
        },
        methods: {
            formatAddress(addressArr) {
                return AddressFormatter(addressArr, this, ', ');
            },
            addressCommentBrs(comment) {
                return stripHtmlTags(
                    JSON.parse(JSON.stringify(
                        comment || ''
                    ))
                );
            }
        }
    };
</script>

<style scoped>
    .value.shipments {
        flex-direction: column;
        align-items: end;
    }
</style>
