<template>
    <NdxPageHeader
        hide-view-mode
        :active-route-callback="activeFilter ? clearFilter : null"
        :nav-name="navName"
        v-model:show-filter="showFilter"
        :active-filter="activeFilter"
    />

    <NdxListFilter
        v-if="filterData !== null"
        v-model:show-filter="showFilter"

        :selected-time-range="filterData.timeRange"
        :time-range-options="timeRangeOptions"
        :state-list-options="stateListOptions"
        :states-selected="filterData.stateList"
        :search-text="filterData.searchText"

        @filter-triggered="updateFilterData"
        @clear-filter="clearFilter"
    />

    <NdxListing
        store-action="orders/getOrders"
        :filter-criteria="filterData"
        @items-changed="updateItems"
    >
        <div class="list">
            <div
                class="roundedBorder"
                v-for="order in orders"
                :key="order.id"
            >
                <table class="order-item">
                    <tbody>
                        <tr>
                            <th scope="row">{{ $t('label.orderNo') }}</th>
                            <td>{{ order.id }}</td>
                        </tr>
                        <tr>
                            <th scope="row">{{ $t('label.orderedAt') }}</th>
                            <td>{{ $d(new Date(order.orderDate), 'long') }}</td>
                        </tr>
                        <tr>
                            <th scope="row">{{ $t('label.orderedBy') }}</th>
                            <td>{{ order.orderedBy.firstname }} {{ order.orderedBy.lastname }}</td>
                        </tr>
                        <tr>
                            <th class="align-top" scope="row">{{ $t('label.sum') }}</th>
                            <td class="align-top">
                                {{ formatPrice(order.priceGross, order.currency) }}
                                <div
                                    v-if="isReduced(order.budgets)"
                                    class="budgetInfo"
                                >
                                    {{ $t('label.lessBudget', {txt: $t('label.budgets')}) }}
                                    {{ formatPrice(order.priceGrossReduced, order.currency) }}
                                </div>
                            </td>
                        </tr>
                        <tr class="no-border">
                            <td colspan="2">
                                <div class="products">
                                    <NdxImage
                                        v-for="(product, idx) in order.products"
                                        :key="idx"
                                        class="squareImg"
                                        :src="getProductImgUrl(product)"
                                        :alt="getProductImgAltText(product)"
                                        :tooltip="getProductImgAltText(product)"
                                        :style="'background-color: ' + getProductImgBgColor(product)"
                                        size="xsOrder"
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr class="no-border">
                            <td></td>
                            <td>
                                <NdxButtonLink @click="gotoOrderDetail(order.id)">
                                    {{ $t('btn.orderDetail') }}
                                </NdxButtonLink>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </NdxListing>
</template>

<script>
    import NdxButtonLink from "../library/NdxButtonLink";
    import NdxImage from "../library/NdxImage";
    import { PriceFormatter } from "../../plugins/formatter";
    import { mapGetters } from "vuex";
    import NdxListing from "../library/NdxListing.vue";
    import NdxListFilter from "../library/NdxListFilter.vue";
    import NdxPageHeader from "../library/NdxPageHeader.vue";

    export default {
        name: "OrdersList",
        components: {
            NdxPageHeader, NdxListFilter, NdxListing, NdxImage, NdxButtonLink
        },
        data() {
            return {
                orders: [],

                timeRangeOptions: [
                    {value: '', text: this.$t('label.list_all')},
                    {value: '7D', text: this.$t('label.list_7days')},
                    {value: '1M', text: this.$t('label.list_1month')},
                    {value: '4M', text: this.$t('label.list_4month')},
                    {value: '6M', text: this.$t('label.list_6month')},
                    {value: '1Y', text: this.$t('label.list_1year')},
                    {value: '2Y', text: this.$t('label.list_2year')},
                    {value: '3Y', text: this.$t('label.list_3year')},
                ],
                stateListOptions: [
                    {value: 0, text: this.$t('label.orderState_0')},
                    {value: 4, text: this.$t('label.orderState_4')},
                    {value: 2, text: this.$t('label.orderState_2')},
                    {value: 3, text: this.$t('label.orderState_3')},
                ],

                showFilter: false,
                filterData: null
            };
        },
        computed: {
            ...mapGetters('shop', {
                defaultProductImageBgColor: 'getDefaultProductImageBgColor'
            }),
            activeFilter() {
                return this.filterData.searchText.length > 0 ||
                    this.filterData.timeRange !== '1M' ||
                    this.filterData.stateList.length !== 4;
            },
            navName() {
                return this.$store.getters['shop/getWgcByType']('orders').label;
            }
        },
        created() {
            this.clearFilter();
        },
        methods: {
            clearFilter() {
                this.showFilter = false;
                this.filterData = {
                    searchText: '',
                    timeRange: '1M',
                    stateList: [0, 2, 3, 4]
                };
            },
            updateFilterData(filterValues) {
                this.filterData = {
                    searchText: filterValues.searchText,
                    timeRange: filterValues.timeRange,
                    stateList: filterValues.stateList
                };
            },
            updateItems(items) {
                this.orders = items;
            },
            isReduced(budgets) {
                return budgets.filter(item => item.modus === 'gift').length > 0;
            },
            formatPrice(value, currency) {
                return PriceFormatter(value, currency, this);
            },
            getProductImgUrl(product) {
                return product.productImage?.filename ?? null;
            },
            getProductImgAltText(product) {
                return product.name;
            },
            getProductImgBgColor(product) {
                return product.productImage?.imageBgColor || this.defaultProductImageBgColor || '#f2f2f2';
            },
            gotoOrderDetail(orderId) {
                this.$router.push({
                    name: 'OrderDetails',
                    params: {
                        id: this.$route.params.id,
                        orderId: orderId
                    }
                });
            }
        }
    };
</script>


<style scoped lang="scss">
    @import "../../style/variables_bootstrap";
    @import "../../style/variables_ndx";
    @import "~bootstrap/scss/mixins/breakpoints";

    .roundedBorder {
        margin-top: 16px;
    }

    .order-item {
        width: 100%;

        .no-border {
            th, td {
                border-bottom: 0 none;
            }
        }

        th, td {
            padding: 8px 16px;
            border-bottom: 1px solid var(--bs-gray-middle);
        }

        th {
            @extend %font-body2;

            color: var(--bs-disabled);
        }

        td {
            @extend %font-h3;

            text-align: end;

            .budgetInfo {
                @extend %font-caption;

                color: var(--bs-disabled);
            }
        }

        .products {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 16px;
            flex-wrap: wrap;

            & > * {
                width: 80px;
                height: 80px;
            }
        }
    }
</style>
