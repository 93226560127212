import uiConfigApi from "../../api/uiConfig";

//state
const state = {
    initiated: false,
    infoText: '',
    helpText: '',

    labelUserData: '',
    labelUserAddress: '',
    labelUserContact: '',

    labelClientData: '',
    labelClientAddress: '',
    labelClientContact: '',

    userItems: [],
    clientItems: [],

    // only for dataCheck
    activeTab: null
};

// getters
const getters = {
    addressProps: (state) => (type, addressTypes) => {
        const items = type === 'user' ? state.userItems : state.clientItems;
        return items
            .filter(item => addressTypes.map(at => at + 'Address').includes(item.referencedEntity) &&
                item.editState > 0)
            .map(item => item.uiElement);
    },
    showUserTab(state) {
        return state.userItems.filter((item) => {
            if (item.referencedEntity === 'mainAddress') {
                return item.editState > 0;
            }
            return ['entity', 'contact'].includes(item.referencedEntity);
        }).length > 0;
    },
    showUserAttributes(state, getters, rootState) {
        const user = rootState.user.user;
        return user?.attributes && Object.keys(user.attributes).length > 0;
    },
    showClientTab(state, getters) {
        const hasItems = state.clientItems.filter((item) => {
            if (item.referencedEntity === 'mainAddress') {
                return item.editState > 0;
            }
            return ['entity', 'contact'].includes(item.referencedEntity);
        }).length > 0;
        return hasItems || getters.showClientAttributes;
    },
    showClientAttributes(state, getters, rootState) {
        const client = rootState.user.client;
        return client?.attributes && Object.keys(client.attributes).length > 0;
    },
    hasEditableItems: (state) => (type) => {
        const items = type === 'user' ? state.userItems : state.clientItems;
        const res = items.find(el => el.editState > 1);
        return res !== undefined;
    },
    getItem: (state) => (uiBlock, referencedEntity, uiElement) => {
        const items = uiBlock === 'user' ? state.userItems : state.clientItems;
        const res = items.find(el => el.uiElement === uiElement && el.referencedEntity === referencedEntity);
        return res !== undefined ? res : null;
    },
    hasOneItem: (state) => (type, referencedEntity, uiElements) => {
        const items = type === 'user' ? state.userItems : state.clientItems;
        for (let idx in uiElements) {
            const res = items.find(
                el => el.uiElement === uiElements[idx]
                    && el.referencedEntity === referencedEntity
                    && el.editState > 0
            );
            if (res !== undefined) {
                return true;
            }
        }
        return false;
    },
    getLabel: (state, getters, rootState, rootGetters) => (type, ctx) => {
        const map = {
            'User': 'label.dataCheck_user',
            'Client': 'label.dataCheck_client',
            'Addresses': 'label.addresses',
            'Settings': 'label.userSettings',
            'Substitutes': 'label.substitutes',
            'Absence': 'label.absence'
        };
        let label = ctx.$t(map[type]);
        const wgc = rootGetters['shop/getWgcByType']('myAccount' + type);
        if (wgc?.label?.length > 0) {
            label = wgc.label;
        }
        return label;
    },
    isMandatoryAddressProp: (state, getters) => (prop, addressTypes, uiBlock) => {
        for (let addressType of addressTypes) {
            const item = getters.getItem(uiBlock, addressType + 'Address', prop);
            if (item && item.editState > 2) {
                return true;
            }
        }
        return false;
    },
    invalidAddressItems: (state, getters) => (address, addressTypes, uiBlock) => {
        let invalidItems = [];
        let props = getters.addressProps(uiBlock, addressTypes);
        for (let prop of props) {
            if (getters.isMandatoryAddressProp(prop, addressTypes, uiBlock)) {
                const isValid = address[prop] && address[prop].length > 0;
                if (!isValid) {
                    invalidItems.push(prop);
                }
            }
        }
        return invalidItems;
    }
};

// actions
const actions = {
    getDataCheckConfig({commit, state}) {
        return new Promise((resolve, reject) => {
            if (state.initiated) {
                resolve();
            } else {
                uiConfigApi.getDataCheckConfig()
                    .then((data) => {
                        commit('INIT_DATA', data);
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    });
            }
        });
    },
    setActiveTabUser({commit}) {
        commit('SET_ACTIVE_TAB', 'user');
    },
    setActiveTabClient({commit}) {
        commit('SET_ACTIVE_TAB', 'client');
    }
};

// mutations
const mutations = {
    RESET(state) {
        state.initiated = false;
        state.infoText = '';
        state.helpText = '';
        state.labelUserData = '';
        state.labelUserAddress = '';
        state.labelUserContact = '';
        state.labelClientData = '';
        state.labelClientAddress = '';
        state.labelClientContact = '';
        state.userItems = [];
        state.clientItems = [];
        state.activeTab = null;
    },
    INIT_DATA(state, data) {
        state.infoText = data.infoText;
        state.helpText = data.helpText;

        state.labelUserData = data.user.label_group_data;
        state.labelUserAddress = data.user.label_group_address;
        state.labelUserContact = data.user.label_group_contact;

        state.labelClientData = data.client.label_group_data;
        state.labelClientAddress = data.client.label_group_address;
        state.labelClientContact = data.client.label_group_contact;

        state.userItems = data.user.items;
        state.clientItems = data.client.items;

        state.initiated = true;
    },
    SET_ACTIVE_TAB(state, tab) {
        state.activeTab = tab;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
