import productApi from '../../api/product';
import orderApi from '../../api/order';
import lodashClone from "lodash/cloneDeep";

function getDefaultQuantity(product) {
    if (product.initialQuantity) {
        return product.initialQuantity;
    }
    return 0;
}

//state
const state = {
    product: null,
    quantity: 0,
    price: 0,
    basePrice: 0,
    additionalPrice: 0,
    /**
     * If set to true the page action is switched from adding new basket item to update basket item
     */
    updateModus: false,
    orderItemId: null,
    quantityChangeable: true,
    orderItemQuantityStrategy: null,
    selectedBudget: null,
    /**
     * Used to delay the rendering of the actions widget
     */
    dataComplete: false
};

// getters
const getters = {
    dataComplete(state) {
        return state.dataComplete;
    },
    orderItemId(state) {
        return state.orderItemId;
    },
    selectedBudget(state) {
        return state.selectedBudget;
    },
    orderItemQuantityStrategy(state) {
        return state.orderItemQuantityStrategy;
    },
    hideDelivery(state) {
        return state.product?.hideDelivery && state.product.deliveryOptions.length <= 1;
    }
};

// actions
const actions = {
    reset({commit}) {
        commit('RESET');
    },
    setQuantity({commit, state}, data) {
        return new Promise((resolve, reject) => {
            commit('SET_QUANTITY', data.quantity);

            productApi.calculatePrice(
                state.product.id,
                state.quantity,
                state.orderItemId
            ).then((priceInfo) => {
                commit('SET_QUANTITY', priceInfo.quantity);
                commit('SET_PRICE', priceInfo.price);
                commit('SET_BASEPRICE', priceInfo.basePrice);
                commit('SET_ADDITIONALPRICE', priceInfo.additionalPrice);
                resolve();
            }).catch((error) => {
                reject(error);
            });
        });
    },
    find({commit, dispatch}, data) {
        return new Promise((resolve, reject) => {
            this.dispatch('products/find', data)
                .then((product) => {
                    commit('SET_PRODUCT', product);
                    dispatch('setQuantity', {
                        quantity: getDefaultQuantity(product)
                    }).then(() => {
                        resolve();
                    }).catch((error) => {
                        reject(error);
                    });
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    findNewVariant({commit, dispatch}, data) {
        return new Promise((resolve, reject) => {
            this.dispatch('products/findNewVariant', data)
                .then((product) => {
                    commit('SET_PRODUCT', product);
                    dispatch('setQuantity', {
                        quantity: getDefaultQuantity(product)
                    }).then(() => {
                        resolve();
                    }).catch((error) => {
                        reject(error);
                    });
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    updateProductFeature({commit}, data) {
        commit('UPDATE_PRODUCT_FEATURE', data);
    },
    updateSelectedBudget({commit}, data) {
        commit('UPDATE_SELECTED_BUDGET', data);
    },
    loadOrders({state}, data) {
        return new Promise((resolve, reject) => {
            orderApi.getOrdersForProduct(
                state.product.id, data.offset, data.limit
            ).then((list) => {
                resolve(list);
            }).catch((error) => {
                reject(error);
            });
        });
    },
    setUpdateModus({commit}, data) {
        commit('SET_UPDATE_MODUS', data);
    },
    setOrderItemId({commit}, data) {
        commit('SET_ORDERITEM_ID', data);
    },
    setOrderItemQuantityStrategy({commit}, data) {
        commit('SET_ORDERITEM_QUANTITYSTRATEGY', data);
    },
    setQuantityChangeable({commit}, data) {
        commit('SET_QUANTITY_CHANGEABLE', data);
    },
    setFavoriteState({commit}, data) {
        commit('SET_FAVORITE_STATE', data);
    },
    setDataComplete({commit}, data) {
        commit('SET_DATA_COMPLETE', data);
    }
};

// mutations
const mutations = {
    RESET(state) {
        state.product = null;
        state.quantity = 0;
        state.price = 0;
        state.basePrice = 0;
        state.updateModus = false;
        state.orderItemId = null;
        state.quantityChangeable = true;
        state.orderItemQuantityStrategy = null;
        state.dataComplete = false;
        state.selectedBudget = null;
    },
    SET_PRODUCT: (state, product) => {
        state.product = product;
    },
    SET_PRICE: (state, price) => {
        state.price = price;
    },
    SET_BASEPRICE: (state, price) => {
        state.basePrice = price;
    },
    SET_ADDITIONALPRICE: (state, price) => {
        state.additionalPrice = price;
    },
    SET_QUANTITY: (state, quantity) => {
        state.quantity = quantity;
    },
    UPDATE_PRODUCT_FEATURE: (state, data) => {
        for (let idx in state.product.productFeatures) {
            if (state.product.productFeatures[idx].id === data.feature.id) {
                state.product.productFeatures[idx].value = lodashClone(data.value);
            }
        }
    },
    SET_UPDATE_MODUS: (state, updateModusActive) => {
        state.updateModus = updateModusActive;
    },
    UPDATE_SELECTED_BUDGET: (state, selectedBudget) => {
        state.selectedBudget = selectedBudget;
    },
    SET_ORDERITEM_ID: (state, orderItemId) => {
        state.orderItemId = orderItemId;
    },
    SET_QUANTITY_CHANGEABLE: (state, changeable) => {
        state.quantityChangeable = changeable;
    },
    SET_ORDERITEM_QUANTITYSTRATEGY: (state, quantityStrategy) => {
        state.orderItemQuantityStrategy = quantityStrategy;
    },
    SET_FAVORITE_STATE: (state, isFavorite) => {
        state.product.isFavorite = isFavorite;
    },
    SET_DATA_COMPLETE: (state, dataComplete) => {
        state.dataComplete = dataComplete;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
