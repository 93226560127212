<template>
    <div class="page-breadcumbs">
        <template
            v-for="(item, idx) of list"
            :key="idx"
        >
            <span v-if="idx > 0">/</span>
            <span
                :class="{
                    action: hasClick(item)
                }"
                @click="onClick(item)"
            >
                <template v-if="'icon' in item && item.icon !== null">
                    <NdxIcon :icon="item.icon" size="s"/>
                </template>
                <template v-else>
                    {{ item.label }}
                </template>
            </span>
        </template>
    </div>
</template>

<script>
    import NdxIcon from "./NdxIcon.vue";

    export default {
        name: 'NdxBreadcrumbs',
        components: {NdxIcon},
        props: {
            breadcrumbs: {
                type: Array
            },
            activeRouteCallback: {
                type: Function
            }
        },
        computed: {
            homeMenuItem() {
                return this.$store.getters['shop/getWgcByType']('shopHomepage');
            },
            activeMenuItem() {
                if ('id' in this.$route.params) {
                    return this.$store.getters['shop/getWgc'](+this.$route.params.id);
                }
                return null;
            },
            list() {
                let list = [{
                    label: this.homeMenuItem.label,
                    click: () => {
                        this.$router.push(this.homeMenuItem.route);
                    }
                }];

                if (this.activeMenuItem) {
                    list.push({
                        label: this.activeMenuItem ? this.activeMenuItem.label : 'active',
                        click: this.activeRouteCallback && typeof this.activeRouteCallback === 'function'
                            ? this.activeRouteCallback
                            : null
                    });
                }

                for (let item of this.breadcrumbs) {
                    list.push(item);
                }

                return list;
            }
        },
        methods: {
            hasClick(item) {
                return 'click' in item && typeof item.click === 'function';
            },
            onClick(item) {
                if (this.hasClick(item)) {
                    item.click();
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
    @import "../../style/variables_ndx";

    .page-breadcumbs {
        @extend %font-h4;
        padding-bottom: 4px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 4px;

        span {
            display: flex;
            align-items: center;
            white-space: nowrap;

            &.action {
                cursor: pointer;

                &:hover {
                    color: var(--bs-primary);
                }
            }
        }
    }
</style>
