import { throbberRules } from "@utilities/throbberRules";
import { ndxRpc } from "../utils/ndxRpc";

const service = 'quickCheckout';

export default {
    setProduct(productId, quantity, productFeatureValues, desiredBudgetId) {
        return new Promise((resolve, reject) => {
            ndxRpc(
                service,
                'setProduct',
                [productId, quantity, productFeatureValues, desiredBudgetId],
                throbberRules.FULLSCREEN_SPINNER
            )
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    setAddress(addressType, addressId) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'setAddress', [addressType, +addressId], throbberRules.FULLSCREEN)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    clear() {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'clear', [], throbberRules.FULLSCREEN_SPINNER)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    order() {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'order', [], throbberRules.FULLSCREEN_SPINNER)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
};
