<script>

    export default {
        name: "BulkDelete",
        data() {
            return {
                selected: [],
                showBulkDeleteDialog: false,
                currentProgress: 0,
                maxProgress: 999,
                showProgress: false
            };
        },
        computed: {
            bulkDeleteInfo() {
                return this.hasBulkItemAction()
                    ? this.$t('message.confirm_bulk_remove', {count: this.selected.length})
                    : this.$t('message.confirm_bulk_delete', {count: this.selected.length});
            },
            hasSelected() {
                return this.selected.length > 0;
            },
            selectionCounter() {
                if (this.hasSelected) {
                    return ' (' + (this.selected.length > 1000 ? this.$n(1000) + '+' : this.selected.length) + ')';
                }

                return '';
            }
        },
        methods: {
            selectionChangeHandler: function (selected) {
                this.selected.splice(0, this.selected.length, ...selected);
            },
            hasBulkItemAction() {
                return 'removeItemAction' in this && typeof (this.removeItemAction) === 'function';
            },
            hasPostDeletionAction() {
                return 'postDeletionAction' in this && typeof (this.postDeletionAction) === 'function';
            },
            onBulkDelete: function () {
                this.showBulkDeleteDialog = true;
                this.maxProgress = this.selected.length;
                this.currentProgress = 0;
                this.showProgress = false;
            },
            bulkDelete: async function () {
                if (!this.storeName && !this.hasBulkItemAction()) {
                    return console.error('provide storeName');
                }
                if (!this.hasPostDeletionAction() && !this.listRefName) {
                    return console.error('provide listRefName');
                }

                if (this.listRefName) {
                    // makes bulk delete for many entries much faster (10x)
                    this.$refs[this.listRefName].resetItems();
                }

                // test error behaviour
                // this.selected.unshift({id: 7654321});

                this.showProgress = true;

                // serialize requests and skip over errors
                for (let item of this.selected) {
                    try {
                        // check if we have a custom action, otherwise call 'delete' method
                        if (this.hasBulkItemAction()) {
                            await this.removeItemAction(item);
                        } else {
                            await this.$store.dispatch(this.storeName + '/delete', {id: item.id});
                        }
                        this.currentProgress++;
                    } catch (e) {
                        console.error(e.message);
                    }
                }

                if (this.hasPostDeletionAction()) {
                    this.postDeletionAction();
                } else {
                    this.$refs[this.listRefName].resetSelected();
                    this.$refs[this.listRefName].update();
                }

                // I want to see the latest progress update
                await new Promise(r => setTimeout(r, 700));

                this.hideBulkDeleteDialog();
            },
            hideBulkDeleteDialog: function () {
                this.showBulkDeleteDialog = false;
            }
        }
    };
</script>
