import shopApi from "../../api/shop";
import uiConfigApi from "../../api/uiConfig";

export const routeMap = {
    '404': {name: 'NotFound', hasIdParams: false, defaultParams: {notFound: 'pageNotFound'}},
    shopHomepage: {name: 'ShopHomepage', hasIdParams: true},
    products: {name: 'ProductsInCategory', hasIdParams: true, defaultParams: {pid: ''}},
    budgets: {name: 'Budgets', hasIdParams: true},
    styleguide: {name: 'AssetsInCategory', hasIdParams: true, defaultParams: {aid: ''}},
    custom: {name: 'Custom', hasIdParams: true},
    favorites: {name: 'Favorites', hasIdParams: true},
    orders: {name: 'Orders', hasIdParams: true},
    watchlist: {name: 'Watchlist', hasIdParams: true},
    workflowsTasks: {name: 'WorkflowsTasks', hasIdParams: true},
    workflowsInstances: {name: 'WorkflowsInstances', hasIdParams: true},
    basket: {name: 'Basket', hasIdParams: true},
    orderPayment: {name: 'Checkout', hasIdParams: true},
    orderSuccess: {name: 'OrderSuccess', hasIdParams: true},
    marketingplanner: {name: 'Marketingplanner', hasIdParams: false},
    news: {name: 'News', hasIdParams: false},
    gtc: {name: 'OptIn', hasIdParams: true},
    documents: {name: 'Documents', hasIdParams: true},
    myAccountUser: {name: 'MyAccountUser', hasIdParams: false},
    myAccountClient: {name: 'MyAccountClient', hasIdParams: false},
    myAccountAddresses: {name: 'MyAccountAddresses', hasIdParams: false},
    myAccountSettings: {name: 'MyAccountUserSettings', hasIdParams: false},
    myAccountSubstitutes: {name: 'MyAccountSubstitutes', hasIdParams: false},
    myAccountAbsence: {name: 'MyAccountAbsence', hasIdParams: false}
};
const iconMap = {
    'shopHomepage': 'home',
    'products': 'labeling',
    'budgets': 'budgets',
    'styleguide': 'asset-management',
    'custom': 'page',
    'favorites': 'bookmarks',
    'orders': 'paper-bag',
    'watchlist': 'paper-bag-draft',
    'basket': 'shopping-cart',
    'marketingplanner': 'marketing-planner',
    'news': 'chat',
    'link': 'OpenInNewWindow',
    'text': 'text',
};

//state
const state = {
    homeRoute: null,

    infoPanelMenu: [],
    primaryMenu: [],
    secondaryMenu: [],
    tertiaryMenu: [],
    headerMenu: [],
    systemPages: [],
    headlineLinks: [],
    footer: [],
    pool: [],
    myAccountMenu: [],

    support: null,
    loginFragment: null,
    loginLogo: null,
    categoryMask: null,
    categoryImageSize: 60,
    logo: null,

    stockInfo: null,
    deliveryCosts: null,
    orderDirectlyActive: false,
    isMainAddressEditingAllowed: false,
    isDeliveryAddressEditingAllowed: false,
    isInvoiceAddressEditingAllowed: false,
    isMainAddressCreateAllowed: false,
    isDeliveryAddressCreateAllowed: false,
    isInvoiceAddressCreateAllowed: false,
    isUserMainAddressEditingAllowed: false,
    isUserDeliveryAddressEditingAllowed: false,
    isUserInvoiceAddressEditingAllowed: false,
    isUserMainAddressCreateAllowed: false,
    isUserDeliveryAddressCreateAllowed: false,
    isUserInvoiceAddressCreateAllowed: false,
    showTax: false,
    productDetails: [],
    basket: {},
    currency: null,
    defaultProductImageBgColor: null,
    multilineProductNames: false,
    removeApproveConfig: {},
    additionalPriceText: '',
    appTracking: null,
    sysMailActiveStates: null,

    rootFolder: {}
};

// getters
const getters = {
    categoryMask() {
        return state.categoryMask;
    },
    categoryImageSize() {
        return state.categoryImageSize;
    },
    currency() {
        return state.currency;
    },
    getDefaultProductImageBgColor() {
        return state.defaultProductImageBgColor;
    },
    isMultilineProductNames() {
        return !!state.multilineProductNames;
    },
    productRootFolderId() {
        return state.rootFolder.product;
    },
    marketingPlannerRootFolderId() {
        return state.rootFolder.marketingPlaner;
    },
    marketingPlannerScRootFolderId() {
        return state.rootFolder.marketingPlanerSc;
    },
    assetLibraryRootFolderId() {
        return state.rootFolder.assetLibrary;
    },
    getWgc() {
        return (wgcId) => state.headerMenu
            .concat(state.infoPanelMenu)
            .concat(state.primaryMenu)
            .concat(state.secondaryMenu)
            .concat(state.tertiaryMenu)
            .concat(state.pool)
            .concat(state.systemPages)
            .concat(state.myAccountMenu)
            .find(
                wgc => wgc.id === wgcId
            );
    },
    getWgcsByType() {
        return (type) => state.headerMenu
            .concat(state.infoPanelMenu)
            .concat(state.primaryMenu)
            .concat(state.secondaryMenu)
            .concat(state.tertiaryMenu)
            .concat(state.pool)
            .concat(state.systemPages)
            .concat(state.myAccountMenu)
            .filter(
                wgc => wgc.type === type
            );
    },
    getWgcByType: (state, getters) => (type) => {
        const list = getters.getWgcsByType(type);
        return list.length === 1 ? list[0] : null;
    },
    getRouteByType: (state) => (type) => {
        const menus = [
            'infoPanelMenu', 'primaryMenu', 'secondaryMenu', 'tertiaryMenu', 'headerMenu', 'systemPages', 'pool',
            'myAccountMenu'
        ];
        for (let menu of menus) {
            for (let item of state[menu]) {
                if (item.type === type) {
                    return item.route;
                }
            }
        }
        return null;
    },
    getIconForType() {
        return (type) => iconMap[type];
    },
    isOrderDirectlyActive(state) {
        return state.orderDirectlyActive;
    }
};

// actions
const actions = {
    loadMenu({commit, state}) {
        return new Promise((resolve, reject) => {
            shopApi.loadMenu()
                .then((data) => {
                    let menus = {
                        infoPanel: [],
                        primary: [],
                        secondary: [],
                        tertiary: [],
                        header: [],
                        system: [],
                        footer: [],
                        pool: [],
                        myAccount: []
                    };

                    let routeParams = null;
                    let mapItem = null;
                    let icon = null;

                    for (let type in data) {
                        for (let item of data[type]) {
                            if (type in menus) {
                                mapItem = item.type in routeMap ? routeMap[item.type] : routeMap['404'];
                                routeParams = mapItem?.hasIdParams ? {id: item.id} : {};
                                if (mapItem.defaultParams) {
                                    routeParams = Object.assign({}, mapItem.defaultParams, routeParams);
                                }
                                if (item.type === 'products') {
                                    routeParams.categoryId =
                                        item.config.productCategoryRootId || state.rootFolder.product;
                                }
                                if (item.type === 'styleguide') {
                                    routeParams.categoryId =
                                        item.config.assetCategoryRootId || state.rootFolder.assetLibrary;
                                }
                                icon = item.image ?? (item.type in iconMap ? iconMap[item.type] : null);

                                menus[type].push({
                                    id: item.id,
                                    label: item.name,
                                    type: item.type,
                                    config: item.config,
                                    icon: icon,
                                    menuBehaviour: item.menuBehaviour,
                                    route: {name: mapItem.name, params: routeParams}
                                });

                                if (item.type === 'shopHomepage') {
                                    commit('SET_HOME_ROUTE', {name: mapItem.name, params: routeParams});
                                }
                            }
                        }
                    }

                    commit('SET_INFOPANEL_MENU', menus.infoPanel);
                    commit('SET_PRIMARY_MENU', menus.primary);
                    commit('SET_SECONDARY_MENU', menus.secondary);
                    commit('SET_TERTIARY_MENU', menus.tertiary);
                    commit('SET_HEADER_MENU', menus.header);
                    commit('SET_SYSTEM_PAGES', menus.system);
                    commit('SET_FOOTER_PAGES', menus.footer);
                    commit('SET_PAGE_POOL', menus.pool);
                    commit('SET_MY_ACCOUNT_MENU', menus.myAccount);
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getDataCheckConfig() {
        return new Promise((resolve, reject) => {
            uiConfigApi.getDataCheckConfig()
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    setLoginData({commit}, data) {
        commit('SET_LOGIN_DATA', data);
    },
    getPreLoginData({commit, rootGetters}, language) {
        return new Promise((resolve, reject) => {
            shopApi.getPreLoginData(rootGetters['session/getShopId'], language).then(data => {
                commit('SET_HEADLINE_LINKS', {links: data.headlineLinks});
                resolve();
            }).catch(err => reject(err));
        });
    },
    setHeadlineLinks({commit}, data) {
        commit('SET_HEADLINE_LINKS', data);
    },
    setShopData({commit, dispatch}, data) {
        commit('SET_SHOP_DATA', data);
        dispatch('marketingPlanning/setGlobalRootId', data.rootFolder.marketingPlaner, {root: true});
        dispatch('marketingPlanning/setClientRootId', data.rootFolder.marketingPlanerSc, {root: true});
        dispatch('marketingPlanning/setClientRootEditable', data.rootFolder.marketingPlanerScEditable, {root: true});
    },
    setShopLogo({commit}, logo) {
        commit('SET_SHOP_LOGO', logo);
    },
    loadOptInPageData() {
        return new Promise((resolve, reject) => {
            shopApi.getOptinPageData()
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    saveOptinPageData({commit}, data) {
        return new Promise((resolve, reject) => {
            shopApi.saveOptinPageData(data)
                .then((data) => {
                    commit('session/SET_OPTIN_PAGE', !data, {root: true});

                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
};

// mutations
const mutations = {
    RESET(state) {
        state.homeRoute = null;
        state.infoPanelMenu = [];
        state.primaryMenu = [];
        state.secondaryMenu = [];
        state.tertiaryMenu = [];
        state.headerMenu = [];
        state.systemPages = [];
        state.footer = [];
        state.pool = [];
        state.logo = null;
        state.stockInfo = null;
        state.deliveryCosts = null;
        state.orderDirectlyActive = false;
        state.showTax = false;
        state.productDetails = [];
        state.basket = {};
        state.support = null;
        state.currency = null;
        state.defaultProductImageBgColor = null;
        state.multilineProductNames = false;
        state.rootFolder = {};
        state.isMainAddressEditingAllowed = false;
        state.isDeliveryAddressEditingAllowed = false;
        state.isInvoiceAddressEditingAllowed = false;
        state.isMainAddressCreateAllowed = false;
        state.isDeliveryAddressCreateAllowed = false;
        state.isInvoiceAddressCreateAllowed = false;
        state.isUserMainAddressEditingAllowed = false;
        state.isUserDeliveryAddressEditingAllowed = false;
        state.isUserInvoiceAddressEditingAllowed = false;
        state.isUserMainAddressCreateAllowed = false;
        state.isUserDeliveryAddressCreateAllowed = false;
        state.isUserInvoiceAddressCreateAllowed = false;
        state.removeApproveConfig = {};
        state.additionalPriceText = '';
        state.sysMailActiveStates = null;
    },
    SET_HOME_ROUTE: (state, homeRoute) => {
        state.homeRoute = homeRoute;
    },
    SET_INFOPANEL_MENU: (state, infoPanel) => {
        state.infoPanelMenu = infoPanel;
    },
    SET_PRIMARY_MENU: (state, primaryMenu) => {
        state.primaryMenu = primaryMenu;
    },
    SET_SECONDARY_MENU: (state, secondaryMenu) => {
        state.secondaryMenu = secondaryMenu;
    },
    SET_TERTIARY_MENU: (state, tertiaryMenu) => {
        state.tertiaryMenu = tertiaryMenu;
    },
    SET_HEADER_MENU: (state, headerMenu) => {
        state.headerMenu = headerMenu;
    },
    SET_SYSTEM_PAGES: (state, systemPages) => {
        state.systemPages = systemPages;
    },
    SET_FOOTER_PAGES: (state, footerPages) => {
        state.footer = footerPages;
    },
    SET_PAGE_POOL: (state, pagePool) => {
        state.pool = pagePool;
    },
    SET_MY_ACCOUNT_MENU: (state, myAccountMenu) => {
        state.myAccountMenu = myAccountMenu;
    },
    SET_LOGIN_DATA: (state, data) => {
        state.loginFragment = data.fragment;
        state.loginLogo = data.logo;
        state.categoryMask = data.categoryMask;
        state.categoryImageSize = data.categoryImageSize;
    },
    SET_HEADLINE_LINKS: (state, data) => {
        state.headlineLinks = data.links;
    },
    SET_SHOP_LOGO: (state, logo) => {
        state.logo = logo;
    },
    SET_SHOP_DATA: (state, data) => {
        state.currency = data.currency;
        state.defaultProductImageBgColor = data.defaultProductImageBgColor || state.defaultProductImageBgColor;
        state.multilineProductNames = data.multilineProductNames;
        state.rootFolder = data.rootFolder;
        state.stockInfo = data.stockInfo;
        state.deliveryCosts = data.deliveryCosts;
        state.orderDirectlyActive = data.orderDirectlyActive;
        state.productDetails = data.productDetails;
        state.basket = data.basket;
        state.showTax = data.showTax;
        state.isMainAddressEditingAllowed = data.isMainAddressEditingAllowed;
        state.isDeliveryAddressEditingAllowed = data.isDeliveryAddressEditingAllowed;
        state.isInvoiceAddressEditingAllowed = data.isInvoiceAddressEditingAllowed;
        state.isMainAddressCreateAllowed = data.isMainAddressCreateAllowed;
        state.isDeliveryAddressCreateAllowed = data.isDeliveryAddressCreateAllowed;
        state.isInvoiceAddressCreateAllowed = data.isInvoiceAddressCreateAllowed;
        state.isUserMainAddressEditingAllowed = data.isUserMainAddressEditingAllowed;
        state.isUserDeliveryAddressEditingAllowed = data.isUserDeliveryAddressEditingAllowed;
        state.isUserInvoiceAddressEditingAllowed = data.isUserInvoiceAddressEditingAllowed;
        state.isUserMainAddressCreateAllowed = data.isUserMainAddressCreateAllowed;
        state.isUserDeliveryAddressCreateAllowed = data.isUserDeliveryAddressCreateAllowed;
        state.isUserInvoiceAddressCreateAllowed = data.isUserInvoiceAddressCreateAllowed;
        state.removeApproveConfig = data.removeApproveConfig;
        state.additionalPriceText = data.additionalPriceText;
        state.appTracking = data.appTracking;
        state.sysMailActiveStates = data.sysMailActiveStates;
        state.categoryImageSize = data.categoryImageSize;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
