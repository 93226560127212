<template>
    <DetailPage :breadcrumb="$t('label.workflow.instanceDetails')">
        <template #visual>
            <PageDetailDefaultVisual>
                <NdxImage
                    :src="visualInfo.path"
                    :style="'background-color: ' + visualInfo.bgColor"
                    size="1000"
                />
            </PageDetailDefaultVisual>
        </template>
        <template #headline>
            {{ $t('label.workflow.instanceDetails') }}
        </template>
        <template #default>
            <div class="task-detail">
                <div class="headline-info mb-2 pb-2">
                    <div class="mt-2 info-txt">
                        <span class="bold">{{ $t('label.workflow.instanceId') }}:</span>
                        {{ timelineData.instance.id }}
                    </div>
                </div>

                <div class="task-info separatorBottom pb-3 mb-3">
                    <div class="keyValues">
                        <div>
                            <span>{{ $t('label.state') }}:</span>
                            {{ getInstanceStatus() }}
                        </div>

                        <div class="mt-2">
                            <span>{{ $t('label.requestedBy') }}: </span>
                            {{ timelineData.businessObject.createdBy.username }}
                        </div>
                        <div v-if="'createdAt' in timelineData.instance && timelineData.instance.createdAt !== null">
                            <span>{{ $t('label.requestedAt') }}: </span>
                            {{ $d(dateConverter(timelineData.instance.createdAt), 'long') }}
                        </div>
                        <div v-if="'endedAt' in timelineData.instance && timelineData.instance.endedAt !== null">
                            <span>{{ $t('label.endedAt') }}: </span>
                            {{ $d(dateConverter(timelineData.instance.endedAt), 'long') }}
                        </div>
                    </div>
                </div>

                <div class="separatorBottom pb-3">
                    <BusinessObjectViewer
                        :business-object="timelineData.businessObject"
                    />
                </div>

                <div
                    v-if="orderItemListData !== null"
                    class="pt-3"
                >
                    <div
                        v-if="orderItemListData.deleted === true"
                        class="no-data"
                    >
                        {{ $t('message.instanceOrderItemDeleted') }}
                    </div>
                    <NdxButton
                        v-else
                        @click="this.$router.push(this.orderItemListData.route);"
                    >{{ orderItemListData.text }}</NdxButton>
                </div>
            </div>
        </template>

        <template #additions>
            <div class="tab-wrapper" :id="'tab-wrapper_' + timelineData.instance.id">
                <div class="tab-nav d-flex flex-wrap gap-3">
                    <NdxButton
                        variant="pill"
                        class="btn pill"
                        :class="{active: activeTab === 'timeline'}"
                        :disabled="!timelineData"
                        @click="activeTab = 'timeline'"
                    >
                        {{ $t('label.timeline') }}
                    </NdxButton>
                    <NdxButton
                        variant="pill"
                        class="btn pill"
                        :class="{active: activeTab === 'logs'}"
                        :disabled="!timelineData"
                        @click="activeTab = 'logs'"
                    >
                        {{ $t('label.logs') }}
                    </NdxButton>
                </div>
                <div class="tab-content">
                    <div v-if="activeTab === 'timeline'" class="timeline">
                        <NdxWorkflowProgression
                            :timeline-data="timelineData"
                            :ndx-icon="ndxIconComponent"
                        />
                    </div>
                    <div v-if="activeTab === 'logs'" class="logs">
                        <WorkflowInstanceTimeline
                            :timeline-data="timelineData"
                        />
                    </div>
                </div>
            </div>
        </template>
    </DetailPage>
</template>

<script>
    import { markRaw } from "vue";
    import BusinessObjectViewer from "./businessObject/BusinessObjectViewer.vue";
    import NdxImage from "../library/NdxImage.vue";
    import NdxButton from "../library/NdxButton.vue";
    import WorkflowInstanceTimeline from "./WorkflowInstanceTimeline.vue";
    import DetailPage from "../../pages/detailPage/DetailPage.vue";
    import NdxIcon from "../library/NdxIcon.vue";
    import NdxWorkflowProgression from "@library/NdxWorkflowProgression.vue";
    import PageDetailDefaultVisual from "../library/PageDetailDefaultVisual.vue";
    import { ndxDateConvert } from "@utilities/ndxDate";

    export default {
        name: 'InstanceDetail',
        components: {
            PageDetailDefaultVisual,
            NdxWorkflowProgression,
            DetailPage, WorkflowInstanceTimeline, BusinessObjectViewer, NdxButton, NdxImage},
        props: {
            timelineData: {
                type: Object
            }
        },
        data() {
            return {
                dateConverter: ndxDateConvert,
                activeTab: 'timeline',
                ndxIconComponent: markRaw(NdxIcon),
                orderItemListData: null
            };
        },
        computed: {
            fakeTask() {
                return {businessObject: this.timelineData.businessObject};
            },
            visualInfo() {
                return this.$store.getters['workflowTask/getInfoForVisual'](this.fakeTask);
            }
        },
        created() {
            this.$store.dispatch('workflowInstance/getOrderItemListData', {
                instanceId: this.timelineData.instance.id
            }).then((result) => {
                if (result.deleted === true) {
                    this.orderItemListData = {
                        deleted: true
                    };
                } else if (result.type === 'order') {
                    const wgc = this.$store.getters['shop/getWgcByType']('orders');
                    if (wgc) {
                        this.orderItemListData = {
                            text: this.$t('btn.openOrder'),
                            route: {
                                name: 'OrderDetails',
                                params: {
                                    id: wgc.id,
                                    orderId: result.typeId
                                }
                            }
                        };
                    }
                } else if (['basket', 'watchlist'].includes(result.type)) {
                    const wgc = this.$store.getters['shop/getWgcByType'](result.type);
                    if (wgc) {
                        this.orderItemListData = {
                            text: wgc.label,
                            route: JSON.parse(JSON.stringify(wgc.route))
                        };
                    }
                }

                if (this.orderItemListData && 'route' in this.orderItemListData) {
                    this.orderItemListData.route.hash = '#orderitem-' + result.orderItemId;
                }

            }).catch((error) => {
                console.error(error);
                this.orderItemListData = null;
            });
        },
        methods: {
            getInstanceStatus() {
                if ('endedAt' in this.timelineData.instance && this.timelineData.instance.endedAt) {
                    return this.$t('label.workflow.state.completed');
                }

                const verdict = this.getValueFromVariables(
                    this.timelineData.instance, 'verdict', null
                );

                return this.getVerdictMessage(verdict);
            },
            getVerdictMessage(verdict) {
                if (verdict === 'reject') {
                    return this.$t('label.workflow.infoReject');
                }
                if (verdict === 'approve') {
                    return this.$t('label.workflow.infoApprove');
                }
                if (verdict === 'revision') {
                    return this.$t('label.workflow.infoRevision');
                }
                return this.$t('label.workflow.infoInProgress');
            },
            getValueFromVariables(object, name, defaultValue) {
                if (!object.variables) {
                    return defaultValue;
                }
                for (let prop in object.variables) {
                    if (prop === name) {
                        return object.variables[name].value;
                    }
                }
                return defaultValue;
            }
        }
    };
</script>

<style scoped lang="scss">
    .tab-content {
        margin-top: 20px;
    }
    .no-data {
        color: var(--bs-disabled) !important;
    }
</style>
