<template>
    <div v-if="order">
        <div class="basket-frame">
            <SummaryWidget
                class="d-block d-xl-none py-3"
                storeName="orders"
                :widget="{config:{isRightSideWidget: false}}"
            />
            <div class="frameContainer">
                <NdxPageHeader
                    hide-view-mode
                    :breadcrumbs="breadcrumbs"
                    :active-route-callback="activeRouteCallback"
                    :nav-name="navName"
                    v-model:show-filter="showFilter"
                    :active-filter="activeBundle !== null"
                    :filter-btn-disabled="!(bundles?.length)"
                />

                <NdxFlyIn v-if="showFilter" class="limit500">
                    <template #title>
                        {{ $t('label.filterHeadline') }}
                    </template>
                    <template #default>
                        <BundleFilter v-if="bundles?.length" type="order" :first-level-bundles="bundles"/>
                    </template>
                    <template #buttons>
                        <NdxButtonLink
                            class="btnFlex"
                            @click="clearFilter"
                            :disabled="activeBundle === null"
                        >
                            {{ $t('btn.reset') }}
                        </NdxButtonLink>
                        <NdxButton class="btnFlex" @click="showFilter = false">
                            {{ $t('btn.close') }}
                        </NdxButton>
                    </template>
                </NdxFlyIn>

                <slot name="message"></slot>

                <OrderSummary :order="order" />

                <div class="groupContainer">
                    <OrderDetailGroup
                        v-for="group in basketGroups"
                        :key="group.groupHash"
                        :group="group"
                        :order-id="orderId"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import OrderSummary from "./OrderSummary";
    import OrderDetailGroup from "./OrderDetailGroup";
    import SummaryWidget from "../../checkout/steps/parts/SummaryWidget";
    import NdxButton from "../../library/NdxButton.vue";
    import NdxFlyIn from "../../library/NdxFlyIn.vue";
    import { mapState } from "vuex";
    import BundleFilter from "../../checkout/steps/parts/BundleFilter.vue";
    import NdxButtonLink from "../../library/NdxButtonLink.vue";
    import NdxPageHeader from "../../library/NdxPageHeader.vue";

    export default {
        name: "OrderDetail",
        components: {
            NdxPageHeader, NdxButtonLink, NdxFlyIn, NdxButton, BundleFilter, SummaryWidget,
            OrderSummary, OrderDetailGroup
        },
        props: {
            orderId: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                showFilter: false,
                order: null,
                isInitializing: true,
            };
        },
        computed: {
            ...mapState({
                bundleBreadcrumbs: state => state.bundles.breadcrumb,
                activeBundle: state => state.bundles.activeBundle
            }),
            bundles() {
                return this.order?.bundleTrees?.filter((tree) => {
                    return tree.children !== null;
                });
            },
            basketGroups() {
                return this.order?.orderItemGroups ?? [];
            },
            breadcrumbs() {
                let list = [{
                    label: this.navName,
                    click: this.activeBundle ? this.clearFilter : null
                }];
                if (this.bundleBreadcrumbs) {
                    for (let item of this.bundleBreadcrumbs) {
                        let click = () => {
                            const path = this.$store.getters['bundles/getPathFromBundleId'](item.bundleId);
                            this.$store.dispatch('bundles/setPath', path);
                        };
                        if (this.activeBundle && this.activeBundle.bundleId === item.bundleId) {
                            click = null;
                        }
                        list.push({
                            label: item.bundleProduct.name,
                            click: click
                        });
                    }
                }
                return list;
            },
            activeRouteCallback() {
                if (this.$route.name === 'OrderSuccess') {
                    return null;
                }
                return () => this.goToOrders();
            },
            navName() {
                return this.$t('label.orderNo') + ' ' + this.order.id;
            }
        },
        watch: {
            bundles: {
                immediate: true,
                handler: function (trees) {
                    this.$store.dispatch('bundles/setBundleTrees', trees);
                }
            }
        },
        created() {
            this.getData();
        },
        mounted() {
            this.$store.dispatch('bundles/resetBundles');
        },
        unmounted() {
            this.$store.dispatch('orders/reset');
            this.$store.dispatch('bundles/resetBundles');
        },
        methods: {
            getData() {
                this.$store.dispatch('jobQueue/loadRunningJobs');
                if (this.orderId) {
                    this.$store.dispatch(
                        'orders/find',
                        {
                            orderId: this.orderId
                        }
                    ).then((data) => {
                        this.order = data;
                        this.isInitializing = false;
                    });
                }
            },
            getOrderItems(orderItemGroup) {
                return this.order.orderItems.filter(
                    orderItem => orderItemGroup.orderItemIdList.includes(orderItem.id)
                );
            },
            clearFilter() {
                this.$store.dispatch('bundles/setPath', null);
                this.showFilter = false;
            },
            goToOrders() {
                const wgc = this.$store.getters['shop/getWgcByType']('orders');
                this.$router.push(wgc.route);
            }
        }
    };
</script>
