<template>
    <div class="myAccount-data-container">
        <div class="groupTitle pb-2" v-if="localGroup?.name?.length > 0">{{ localGroup.name }}</div>
        <div class="groupDesc pb-2 px-0" v-if="localGroup?.description?.length > 0">{{ localGroup.description }}</div>
        <div class="myAccount-data mb-4">
            <CustomFieldGroupEntries
                v-if="entries.length > 0"
                ref="groupEntries"
                :entries="entries"
                :invalid-field-ids="invalidFieldIds"
                @update-value="validate"
            />

            <div
                v-if="childGroupOptions.length > 0"
                class="p-3"
            >
                <NdxSelect
                    variant="outline"
                    :options="childGroupOptions"
                    :invalid="!groupOptionValid"
                    v-model:model-value="selectedChildGroupId"
                />

                <CustomFieldGroup
                    v-if="selectedChildGroup !== undefined"
                    ref="checkoutGroup"
                    class="pt-3"
                    :group="selectedChildGroup"
                    @update-is-valid="updateChildGroupValidation"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import CustomFieldGroupEntries from "./CustomFieldGroupEntries.vue";
    import NdxSelect from "../../../library/formElements/NdxSelect.vue";
    import { cloneDeep } from "lodash";

    export default {
        name: "CustomFieldGroup",
        components: {NdxSelect, CustomFieldGroupEntries},
        props: {
            group: {
                type: Object
            }
        },
        emits: ['update-is-valid'],
        data() {
            return {
                localGroup: null,
                updatedFields: {},
                invalidFieldIds: [],
                selectedChildGroupId: null,

                groupIsValid: false,

                groupOptionIsOptional: true,
                childGroupIsValid: false
            };
        },
        computed: {
            entries() {
                if (this.localGroup?.entries?.length > 0) {
                    return this.localGroup.entries;
                }
                if (this.localGroup?.groups?.length === 1 && this.localGroup.groups[0].entries.length > 0) {
                    return this.localGroup.groups[0].entries;
                }
                return [];
            },
            childGroups() {
                if (this.localGroup?.groups?.length > 1) {
                    return this.localGroup.groups;
                }
                return [];
            },
            childGroupOptions() {
                if (!this.childGroups.length) {
                    return [];
                }

                let options = [{
                    value: null,
                    text: this.groupOptionIsOptional
                        ? this.$t('label.select_no_choice')
                        : this.$t('label.select_make_choice')
                }];
                this.childGroups.forEach((group) => {
                    options.push({
                        value: group.id,
                        text: group.name
                    });
                });
                return options;
            },
            selectedChildGroup() {
                return this.childGroups.find(group => group.id === this.selectedChildGroupId);
            },
            selectedChildGroupIsValid() {
                if (this.selectedChildGroup !== undefined) {
                    return this.childGroupIsValid;
                }
                return true;
            },
            groupOptionValid() {
                return !this.childGroupOptions.length
                    || this.groupOptionIsOptional
                    || this.selectedChildGroup !== undefined;
            },
            isValid() {
                return this.groupIsValid && this.groupOptionValid && this.selectedChildGroupIsValid;
            }
        },
        watch: {
            group: {
                deep: true,
                handler: 'initGroup'
            },
            isValid: {
                immediate: true,
                handler: function () {
                    this.$emit('update-is-valid', this.isValid);
                }
            }
        },
        mounted() {
            this.initGroup();
        },
        methods: {
            initGroup() {
                this.localGroup = cloneDeep(this.group);
                const selectedChildGroup = this.childGroups.find((group) => {
                    return this.getEntries(group).length > 0 || this.getSubEntries(group).length > 0;
                });
                if (selectedChildGroup !== undefined) {
                    this.selectedChildGroupId = selectedChildGroup.id;
                }
                this.validate();
            },
            getSubEntries(group) {
                let entries = this.getEntries(group);
                group.groups.forEach((subGroup) => {
                    entries.push(...this.getSubEntries(subGroup));
                });
                return entries;
            },
            getEntries(group) {
                return group.entries.filter(entry => entry.value !== null);
            },
            calculateUpdatedFields() {
                let fields = {};

                if (this.$refs?.groupEntries) {
                    fields = this.$refs.groupEntries.getUpdatedFields();
                }

                return fields;
            },
            getUpdatedFields() {
                let fields = {...this.calculateUpdatedFields()};

                if ('checkoutGroup' in this.$refs && this.$refs.checkoutGroup) {
                    let childFields = this.$refs.checkoutGroup.getUpdatedFields();
                    if (Object.keys(childFields).length) {
                        fields = {...fields, ...childFields};
                    }
                }

                return fields;
            },
            updateChildGroupValidation(isValid) {
                this.childGroupIsValid = isValid;
            },
            validate() {
                let isValid = true;
                const updatedFields = this.calculateUpdatedFields();
                this.invalidFieldIds = [];

                const isInvalidValue = (value) => {
                    const _value = typeof value === 'string' ? value.trim() : value;
                    return [null, undefined, ''].includes(_value);
                };
                const checkboxInvalid = (type, value) => {
                    return type === 'checkbox' && !['1', 1, true].includes(value);
                };
                const selectMultiInvalid = (type, value) => {
                    return type === 'selectMulti' && (!Array.isArray(value) || !value.length);
                };

                const fileInvalid = (type, value) => {
                    return type === 'file' && (
                        value === null
                        || (typeof value === 'object' && !Object.keys(value).length)
                        || (typeof value === 'object' && value.deleteFile === true)
                    );
                };

                this.entries.forEach(entry => {
                    if (entry.mandatory) {
                        let type = entry.attributeDef.valueFormatType;
                        let value = entry.id in updatedFields ? updatedFields[entry.id].value : null;

                        if (value === null) {
                            value = entry.attributeDef.value;
                            if (value === null) {
                                value = entry.attributeDef.defaultValue;
                            }
                        }

                        if (isInvalidValue(value) ||
                            checkboxInvalid(type, value) ||
                            selectMultiInvalid(type, value) ||
                            fileInvalid(type, value)
                        ) {
                            this.invalidFieldIds.push(entry.id);
                            isValid = false;
                        }
                    }
                });

                this.groupIsValid = isValid;
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "../../../../style/variables_ndx";

    .groupTitle {
        @extend %font-h2;
    }

    .groupDesc {
        @extend %font-body2;
    }
</style>
