<template>
    <CustomFieldGroup
        v-for="(group) in groups"
        :key="group.id"
        ref="checkoutGroups"
        :group="group"
        @update-is-valid="(isValid) => updateGroupValid(group.id, isValid)"
    />
</template>

<script>
    import { cloneDeep } from "lodash";
    import CustomFieldGroup from "./parts/CustomFieldGroup.vue";

    export default {
        name: "CustomFields",
        components: {CustomFieldGroup},
        data() {
            return {
                groups: [],
                invalidFieldIds: [],
                groupValidations: {}
            };
        },
        created() {
            const wgc = this.$store.getters['shop/getWgcByType']('additionalCheckoutStep');
            if (wgc) {
                this.$store.dispatch('widgets/getWidgets', {
                    parentOid: {
                        'type': 'WidgetGroupConfig',
                        'id': wgc.id
                    }
                }).then((widgets) => {
                    this.$store.dispatch('widgets/setCustomWidgetList', widgets);
                });
            }
        },
        mounted() {
            this.$store.dispatch('checkout/getCustomFields').then((groups) => {
                this.groups = cloneDeep(groups);
                this.checkValidations();
            });
        },
        beforeUnmount() {
            let updatedFields = {};

            if ('checkoutGroups' in this.$refs) {
                this.$refs.checkoutGroups.forEach(item => {
                    const fields = item.getUpdatedFields();
                    updatedFields = {...updatedFields, ...fields};
                });
            }

            if (Object.keys(updatedFields).length) {
                this.$store.dispatch('checkout/saveCustomFields', updatedFields);
            }
        },
        unmounted() {
            this.$store.dispatch('widgets/setCustomWidgetList', []);
        },
        methods: {
            updateGroupValid(groupId, isValid) {
                this.groupValidations[groupId] = isValid;
                this.checkValidations();
            },
            checkValidations() {
                let isValid = false;
                if (Object.keys(this.groupValidations).length) {
                    isValid = Object.values(this.groupValidations).every(value => value === true);
                }
                this.$store.dispatch('checkout/setIsValid', isValid);
            }
        }
    };
</script>
