import shopApi from "../../api/shop";

//state
const state = {
    widgetCache: {},
    customWidgetList: []
};

// getters
const getters = {
    customWidgetList(state) {
        return state.customWidgetList;
    }
};

// actions
const actions = {
    getWidgets({state, commit}, params) {
        return new Promise((resolve, reject) => {
            const key = JSON.stringify(params.parentOid);
            if (key in state.widgetCache) {
                resolve(state.widgetCache[key]);
            } else {
                shopApi.getWidgets(params.parentOid)
                    .then((data) => {
                        commit('ADD_WIDGET_CACHE_ENTRY', {
                            key: key,
                            widgets: data
                        });
                        resolve(data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            }
        });
    },
    clearWidgetCache({commit}) {
        commit('RESET');
    },
    setCustomWidgetList({commit}, list) {
        commit('SET_CUSTOM_WIDGET_LIST', list);
    }
};

// mutations
const mutations = {
    RESET(state) {
        state.widgetCache = {};
        state.customWidgetList = [];
    },
    ADD_WIDGET_CACHE_ENTRY(state, data) {
        state.widgetCache[data.key] = data.widgets;
    },
    SET_CUSTOM_WIDGET_LIST(state, data) {
        state.customWidgetList = Array.isArray(data) ? data : [];
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
