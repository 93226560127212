<template>
    <div
        class="quantityDiscrete d-flex align-items-center justify-content-between"
        :class="{inactive: printView || !quantityChangeable}"
    >
        <NdxIcon
            v-if="!printView && (quantityChangeable || onlyChangeOperationIsRemove)"
            :icon="currentQuantity === 0 || (isQuantityZeroAllowed && currentQuantity === minQuantity)
                ? 'trash' : 'remove'"
            :is-action="!decreaseDisabled"
            :class="{
                disabled: decreaseDisabled
            }"
            @click="decreaseQuantity"
            size="m"
        />
        <NdxSelect
            class="select-wrapper"
            :key="currentQuantity"
            variant="noStyle"
            :model-value="Math.round(currentQuantity)"
            :options="selectOptions"
            @update:model-value="setNewQuantity"
            :readonly="!quantityChangeable && !onlyChangeOperationIsRemove"
            :support-search="false"
        />
        <NdxIcon
            v-if="!printView && quantityChangeable"
            icon="add"
            :is-action="maxQuantity === undefined || currentQuantity < maxQuantity"
            :class="{
                disabled: maxQuantity !== undefined && currentQuantity >= maxQuantity
            }"
            @click="increaseQuantity"
            size="m"
        />
    </div>
</template>

<script>
    import NdxIcon from "../../library/NdxIcon";
    import NdxSelect from "../../library/formElements/NdxSelect";
    import { mapState } from "vuex";

    export default {
        name: "QuantityDiscrete",
        components: {NdxSelect, NdxIcon},
        emits: ['quantity-changed'],
        props: {
            data: {
                type: Array,
                required: true
            },
            currentQuantity: {
                type: Number,
                required: true
            },
            readonly: {
                type: Boolean
            },
            minQuantityByPricelist: {
                type: Number,
                default: 1
            },
            isQuantityZeroAllowed: {
                type: Boolean,
                required: true
            },
            onlyChangeOperationIsRemove: {
                type: Boolean
            }
        },
        computed: {
            ...mapState({
                printView: state => state.basket.printView
            }),
            minQuantity: function () {
                if (!this.sortedValues.length) {
                    return 0;
                }
                if (this.sortedValues.length === 1) {
                    return this.sortedValues[0];
                }
                return this.sortedValues[0] !== 0 ? this.sortedValues[0] : this.sortedValues[1];
            },
            maxQuantity: function () {
                return this.sortedValues.length ? this.sortedValues[this.sortedValues.length - 1] : undefined;
            },
            sortedValues: function () {
                let values = JSON.parse(JSON.stringify(this.data));

                if (this.onlyChangeOperationIsRemove) {
                    values = [this.currentQuantity];
                }

                if (this.isQuantityZeroAllowed) {
                    values.unshift(0);
                }

                return values.sort(
                    (a, b) => parseFloat(a) > parseFloat(b) ? 1 : -1
                );
            },
            selectOptions: function () {
                let options = [];

                this.sortedValues.forEach(allowedQuantity => {
                    if (this.minQuantityByPricelist <= allowedQuantity ||
                        this.isQuantityZeroAllowed && allowedQuantity === 0
                    ) {
                        options.push({
                            value: allowedQuantity,
                            text: this.$n(allowedQuantity)
                        });
                    }
                });

                if (!options.find((entry) => entry.value === this.currentQuantity)) {
                    options.splice(0, 0, {
                        value: this.currentQuantity,
                        text: this.$n(this.currentQuantity)
                    });
                }

                return options;
            },
            currentListIdx: function () {
                return this.sortedValues.indexOf(parseFloat(this.currentQuantity));
            },
            quantityChangeable: function () {
                return !this.printView && !this.readonly && this.sortedValues.length > 1;
            },
            decreaseDisabled() {
                return this.currentQuantity === 0 ||
                    (!this.isQuantityZeroAllowed && this.currentQuantity <= this.minQuantity);
            }
        },
        methods: {
            increaseQuantity() {
                if (this.currentListIdx < this.sortedValues.length - 1) {
                    this.$emit('quantity-changed', this.sortedValues[this.currentListIdx + 1]);
                }
            },
            decreaseQuantity() {
                if (this.decreaseDisabled) {
                    return;
                }
                if (this.currentListIdx > 0 &&
                    (this.sortedValues[this.currentListIdx - 1] >= this.minQuantityByPricelist ||
                        this.sortedValues[this.currentListIdx - 1] === 0 && this.isQuantityZeroAllowed
                    )
                ) {
                    this.$emit('quantity-changed', this.sortedValues[this.currentListIdx - 1]);
                }
            },
            setNewQuantity(newQuantity) {
                if (newQuantity !== this.currentQuantity) {
                    this.$emit('quantity-changed', newQuantity);
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
    .quantityDiscrete {
        border: 2px solid var(--bs-gray-middle);
        border-radius: 8px;
        padding: 0 8px;
        max-width: 100%;
        gap: 8px;

        .bi.disabled {
            color: var(--bs-disabled);
        }

        .select-wrapper {
            background-color: var(--bs-gray-light);
            height: 40px;

            :deep(.dropdown) {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
            }
        }

        &.inactive {
            .select-wrapper {
                background-color: white;
            }
        }
    }
</style>
