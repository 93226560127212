<template>
    <SummaryWidget
        :widget="widget"
        v-bind="$attrs"
        class="basketSummary"
    >
        <template #actions>
            <div class="actionsWrapper">
                <NdxButton
                    v-for="(action, idx) of actions"
                    :key="action.type"

                    class="text-nowrap justify-content-between"
                    :variant="idx === 0 ? 'primary' : 'secondary'"
                    :disabled="action.disabled || !showBasketSummary"
                    @click="action.click"
                >
                    <div class="d-flex justify-content-between">
                        {{ action.label }}
                        <NdxIcon
                            v-if="action.icon !== null"
                            :icon="action.icon.icon"
                            :flip-h="action.icon.flipH"
                        />
                    </div>
                </NdxButton>
            </div>
        </template>
    </SummaryWidget>
</template>

<script>
    import { mapGetters, mapState } from "vuex";
    import NdxButton from "../../library/NdxButton";
    import NdxIcon from "../../library/NdxIcon";
    import SummaryWidget from "../../checkout/steps/parts/SummaryWidget";

    export default {
        name: "BasketSummary",
        components: {SummaryWidget, NdxButton, NdxIcon},
        props: {
            widget: {
                type: Object
            }
        },
        computed: {
            ...mapState({
                showOrderReliablyBtn: state => state.shop.productDetails.showOrderReliablyBtn,
                showBasketSummary: state => state.basket.showBasketSummary,
            }),
            ...mapGetters('basket', {
                isMultiAddressDelivery: 'isMultiAddressDelivery',
                productCount: 'getProductCount',
                orderable: 'isOrderable',
                orderableMinimalCheck: 'isOrderableMinimalCheck',
                stockCheckFailed: 'stockQuantityUnderCut',
                areProductFeaturesValid: 'areProductFeaturesValid',
                hasLockedCustomization: 'hasLockedCustomization',
                getBasketCallToAction: 'getBasketCallToAction',
                getBasketTextCheckout: 'getBasketTextCheckout',
                getBasketTextQuickCheckout: 'getBasketTextQuickCheckout',
                isBasketCheckoutActive: 'isBasketCheckoutActive',
                isBasketQuickCheckoutActive: 'isBasketQuickCheckoutActive'
            }),
            hasOrderPaymentPage() {
                return !!this.$store.getters['shop/getRouteByType']('orderPayment');
            },
            isOrderReliablyAllowed() {
                return this.hasOrderPaymentPage && this.$route.name !== 'Checkout' || this.showOrderReliablyBtn;
            },
            quickCheckoutPossible() {
                return !this.isMultiAddressDelivery && this.orderable;
            },
            goToCheckoutBtnDisabled() {
                return this.productCount === 0 ||
                    !this.isOrderReliablyAllowed ||
                    this.stockCheckFailed ||
                    !this.areProductFeaturesValid ||
                    this.hasLockedCustomization ||
                    !this.orderableMinimalCheck;
            },
            actions() {
                const checkout = {
                    type: 'checkout',
                    disabled: this.goToCheckoutBtnDisabled,
                    click: this.gotoCheckout,
                    label: this.getBasketTextCheckout
                        ? this.getBasketTextCheckout
                        : this.$t('btn.order'),
                    icon: null
                };
                const quickCheckout = {
                    type: 'quickCheckout',
                    disabled: !this.quickCheckoutPossible,
                    click: this.getQuickCheckoutData,
                    label: this.getBasketTextQuickCheckout
                        ? this.getBasketTextQuickCheckout
                        : this.$t('btn.quickCheckout'),
                    icon: null
                };

                let actions = [];

                if (this.isBasketQuickCheckoutActive) {
                    actions.push(quickCheckout);
                }

                if (this.hasOrderPaymentPage && this.isBasketCheckoutActive) {
                    if (this.getBasketCallToAction === 'quickCheckout') {
                        actions.push(checkout);
                    } else {
                        actions.unshift(checkout);
                    }
                }

                if (actions.length > 0) {
                    actions[0].icon = {
                        icon: 'arrow-left',
                        flipH: true
                    };
                }

                return actions;
            }
        },
        methods: {
            getQuickCheckoutData() {
                if (this.quickCheckoutPossible) {
                    this.$store.dispatch('basket/getQuickCheckoutData');
                }
            },
            gotoCheckout() {
                const route = this.$store.getters['shop/getRouteByType']('orderPayment');
                this.$router.push(route);
            }
        }
    };
</script>
