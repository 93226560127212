<template>
    <DataItem
        v-for="item of entries"
        :key="item.id"

        ref="dataItem"
        class="myAccount-data-item"
        :class="{
            isMandatory: item.mandatory,
            isInvalid: invalidFieldIds.includes(item.id)
        }"
        :item="item"
        type="checkoutAttribute"
        opened
        allow-html-desc
        :model-value="item"
        @update:modelValue="updateValue"
    />
</template>

<script>
    import DataItem from "../../../myAccount/dataItem/DataItem";

    export default {
        name: "CustomFieldGroupEntries",
        components: {DataItem},
        emits: ['update-value'],
        props: {
            entries: {
                type: Array
            },
            invalidFieldIds: {
                type: Array
            }
        },
        watch: {
            entries: {
                deep: true,
                handler: 'updateValue'
            }
        },
        methods: {
            updateValue() {
                this.$emit('update-value');
            },
            getUpdatedFields() {
                let updatedFields = {};

                if ('dataItem' in this.$refs) {
                    this.$refs.dataItem.forEach(item => {
                        updatedFields[item.modelValue.id] = {
                            type: item.modelValue.attributeDef.valueFormatType,
                            key: item.modelValue.attributeDef.key,
                            value: item.value
                        };
                    });
                }

                return updatedFields;
            }
        }
    };
</script>

<style scoped lang="scss">
    .isMandatory {
        :deep(.key:after) {
            content: " *"
        }
    }

    .isInvalid {
        background-color: var(--bs-danger-light);
    }
</style>
