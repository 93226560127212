<template>
    <NdxPageHeader
        :nav-name="navName"
        v-model:view-mode="viewMode"
        view-mode-storage-key="workflowViewMode"
        hide-filter
    />

    <div
        class="card-container"
        :class="{['viewmode-' + viewMode]: true}"
    >
        <NdxCategoryCard
            :title="activeLabel"
            card-icon="todos"
            :variant="viewMode"
            @click="goto('active')"
        />
        <NdxCategoryCard
            :title="completedLabel"
            card-icon="done-circle"
            :variant="viewMode"
            @click="goto('completed')"
        />
    </div>
</template>

<script>
    import NdxCategoryCard from "../library/NdxCategoryCard";
    import NdxPageHeader from "../library/NdxPageHeader.vue";

    export default {
        name: 'Workflow',
        components: {NdxPageHeader, NdxCategoryCard},
        data() {
            return {
                viewMode: 'card',
            };
        },
        computed: {
            subkind() {
                return this.$route.name;
            },
            isWfInstances() {
                return this.subkind.toLowerCase().includes('instance');
            },
            activeLabel() {
                return this.isWfInstances ?
                    this.$t('label.workflow.workflowInstancesActive') :
                    this.$t('label.workflow.workflowTasksActive');
            },
            completedLabel() {
                return this.isWfInstances ?
                    this.$t('label.workflow.workflowInstancesCompleted') :
                    this.$t('label.workflow.workflowTasksCompleted');
            },
            navName() {
                return this.isWfInstances ?
                    this.$store.getters['shop/getWgcByType']('workflowsInstances').label :
                    this.$store.getters['shop/getWgcByType']('workflowsTasks').label;

            }
        },
        methods: {
            goto(taskType) {
                const routeName = this.isWfInstances ? 'WorkflowsInstancesTypeFiltered' : 'WorkflowsTasksTypeFiltered';
                let route = {
                    name: routeName,
                    params: {
                        ...this.$route.params,
                        taskType: taskType
                    }
                };
                this.$router.push(route);
            },
        }
    };
</script>

<style lang="scss" scoped>
    @import "~bootstrap/scss/bootstrap-utilities";

    .card-container.viewmode-list {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
    }

    .card-container.viewmode-card {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
        gap: 16px;
    }

    @include media-breakpoint-up(md) {
        .card-container.viewmode-card {
            grid-template-columns: repeat(auto-fill, minmax(222px, 1fr));
        }
    }
</style>
