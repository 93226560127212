<template>
    <NdxPageHeader
        :breadcrumbs="breadcrumbs"
        :active-route-callback="goToInstances"
        :nav-name="navName"
        :active-filter="activeFilter"
        v-model:show-filter="showFilter"
        hide-view-mode
    />

    <NdxListFilter
        v-if="filterData !== null"
        v-model:show-filter="showFilter"
        :search-text="filterData.searchText"
        :start-date="filterData.startDate"
        :end-date="filterData.endDate"
        @filter-triggered="updateFilterData"
        @clear-filter="clearFilter"
    />

    <WorkflowInstancesActive
        v-if="type === 'active'"
        :filter-data="filterData"
    />
    <WorkflowInstancesCompleted
        v-else
        :filter-data="filterData"
    />
</template>

<script>
    import WorkflowInstancesActive from "./WorkflowInstancesActive.vue";
    import WorkflowInstancesCompleted from "./WorkflowInstancesCompleted.vue";
    import NdxListFilter from "../library/NdxListFilter.vue";
    import NdxPageHeader from "../library/NdxPageHeader.vue";

    const LISTTYPE_ACTIVE = 'active';
    const LISTTYPE_COMPLETED = 'completed';

    export default {
        name: 'WorkflowInstances',
        components: {
            NdxPageHeader, NdxListFilter, WorkflowInstancesCompleted, WorkflowInstancesActive
        },
        data() {
            return {
                type: LISTTYPE_ACTIVE,
                filterData: null,
                showFilter: false
            };
        },
        computed: {
            navName() {
                return this.type === LISTTYPE_ACTIVE
                    ? this.$t('label.workflow.workflowInstancesActive')
                    : this.$t('label.workflow.workflowInstancesCompleted');
            },
            breadcrumbs() {
                return [{
                    label: this.navName,
                    click: this.activeFilter ? () => this.clearFilter() : null
                }];
            },
            activeFilter() {
                return this.filterData.searchText.length > 0 ||
                    ![null, undefined].includes(this.filterData?.startDate?.from) ||
                    ![null, undefined].includes(this.filterData?.startDate?.to) ||
                    ![null, undefined].includes(this.filterData?.endDate?.from) ||
                    ![null, undefined].includes(this.filterData?.endDate?.to);
            }
        },
        watch: {
            '$route.params': {
                immediate: true,
                handler: function (params) {
                    const routeParam = params.taskType;
                    const isValidRoutParam = [LISTTYPE_ACTIVE, LISTTYPE_COMPLETED].includes(routeParam);
                    this.type = isValidRoutParam ? routeParam : LISTTYPE_ACTIVE;
                    this.clearFilter();
                }
            }
        },
        created() {
            this.clearFilter();
        },
        methods: {
            goToInstances() {
                this.$router.push(
                    this.$store.getters['shop/getWgcByType']('workflowsInstances').route
                );
            },
            clearFilter() {
                this.showFilter = false;
                this.filterData = {
                    searchText: '',
                    startDate: {from: null, to: null},
                    endDate: this.type === LISTTYPE_COMPLETED
                        ? {from: null, to: null}
                        : null
                };
            },
            updateFilterData(filterValues) {
                this.filterData = {
                    searchText: filterValues.searchText,
                    startDate: {
                        from: filterValues.startDateFrom,
                        to: filterValues.startDateTo
                    },
                    endDate: this.type === LISTTYPE_COMPLETED
                        ? {
                            from: filterValues.endDateFrom,
                            to: filterValues.endDateTo
                        }
                        : null

                };
            }
        }
    };
</script>
