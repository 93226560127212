<template>
    <Group :group="computedGroup" v-if="group.items.length">
        <template #header-name-suffix v-if="possibleOrderItems.length && !bulkModeActive">
            <NdxButcon class="hideOnPrintView" icon="arrow-left" size="xs" @click="showPicker"/>
        </template>
        <template #group-items>
            <NdxCheckbox
                v-if="bulkModeActive"
                :label="$t('btn.selectAll')"
                class="multiSelector"
                @update:modelValue="(val) => this.selectAll(val)"
            />
            <template v-for="item in group.items" :key="item.orderItemId">
                <div class="bundleItemContainer d-flex">
                    <NdxCheckbox
                        v-if="bulkModeActive"
                        :model-value="isSelected(item)"
                        @update:model-value="(val) => selectBundle(val, item)"
                    />
                    <div class="flex-fill">
                        <BasketGroupItem
                            :item="item"
                            @refresh-basket="() => $emit('refresh-basket')"
                        />
                    </div>
                    <div
                        v-if="bulkModeActive"
                        class="clickCatcher"
                        :class="{selected: isSelected(item)}"
                        @click="selectBundle(!isSelected(item), item)"
                    ></div>
                </div>
            </template>

        </template>
    </Group>
    <NdxFlyIn v-if="pickerVisible">
        <template #title>
            {{ $t('label.product') }}
        </template>
        <template #default>
            <div class="d-flex gap-3 flex-column">
                <NdxSearchInput
                    class="w-100 pt-3"
                    v-model:model-value="searchText"
                    removable
                />
                <div
                    v-for="item of possibleOrderItemsReduced"
                    :key="item.orderItemId"

                    class="picker-row-item d-flex gap-3 flex-row pt-3"
                >
                    <div class="picker-row-item" @click.stop.prevent="toggleSelectedItems(item.orderItemId)"></div>
                    <NdxCheckbox
                        class="flex-shrink-0 picker-checkbox"
                        :model-value="selectedItems.has(item.orderItemId)"
                        @update:model-value="() => toggleSelectedItems(item.orderItemId)"
                    />
                    <GroupItem
                        class="flex-grow-1"
                        :item="item"
                        hide-call-to-actions
                        hide-notifications
                    />
                </div>
            </div>
        </template>
        <template #buttons>
            <NdxButtonLink class="btnFlex" @click="closePicker">
                {{ $t('btn.cancel') }}
            </NdxButtonLink>
            <NdxButton class="btnFlex" @click="updateBudgetSelection" :disabled="!selectedItems.size">
                {{ $t('label.save') }}
                <template v-if="selectedItems.size > 0">
                    ({{selectedItems.size}} {{ $tc('label.products', selectedItems.size) }})
                </template>
            </NdxButton>
        </template>
    </NdxFlyIn>
</template>

<script>
    import BasketGroupItem from "./BasketGroupItem.vue";
    import Group from "../../checkout/steps/parts/Group.vue";
    import NdxButton from "../../library/NdxButton.vue";
    import NdxButtonLink from "../../library/NdxButtonLink.vue";
    import NdxFlyIn from "../../library/NdxFlyIn.vue";
    import {mapGetters, mapState} from "vuex";
    import GroupItem from "../../checkout/steps/parts/GroupItem.vue";
    import NdxCheckbox from "../../library/formElements/NdxCheckbox.vue";
    import NdxSearchInput from "../../library/formElements/NdxSearchInput.vue";
    import NdxButcon from "../../library/NdxButcon.vue";

    export default {
        name: 'BasketBudgetGroup',
        components: {
            NdxButcon, NdxSearchInput, NdxCheckbox, GroupItem, NdxFlyIn, NdxButtonLink, NdxButton,
            Group, BasketGroupItem
        },
        props: {
            group: {
                type: Object,
                required: true
            },
            orderItems: {
                type: Array,
                default: function () {
                    return [];
                }
            },
            bulkModeActive: {
                type: Boolean
            },
            selectedBundles: {
                type: Array
            }
        },
        emits: ['refresh-basket', 'select-bundle'],
        data() {
            return {
                pickerVisible: false,
                selectedItems: null,
                searchText: ''
            };
        },
        computed: {
            ...mapState({
                bundleTrees: state => state.basket.basket.bundleTrees
            }),
            ...mapGetters('shop', [
                'currency'
            ]),
            computedGroup() {
                const vendor = this.group.budget
                    ? this.group.budget
                    : {name: this.$t('label.ownCosts')};
                const summary = {
                    priceNet: 0.0,
                    budgets: this.group.budget ? [this.group.budget] : []
                };

                this.group.items.forEach(item => {
                    summary.priceNet = summary.priceNet + this.$store.getters['basket/budgetRelevantCosts'](item);
                });

                return {
                    vendor: vendor,
                    items: this.group.items,
                    summary: summary,
                    deliveryOption: {
                        priceTag: this.$t('label.incl_delivery')
                    }
                };
            },
            possibleOrderItems() {
                const supportNoBudgetUse = (item) => {
                    return item.availableBudgets
                        .reduce((prevVal, budget) => prevVal || !budget.restrictedProducts, false);
                };
                if (!this.group.budget) {
                    return this.orderItems.filter((item) => {
                        return item.budget !== null && supportNoBudgetUse(item);
                    });
                }
                return this.orderItems.filter((item) => {
                    if (item.budget?.id === this.group.budget.id) {
                        return false;
                    }
                    return item.availableBudgets
                        .filter(budget => budget.id === this.group.budget.id)
                        .length > 0;
                });
            },
            possibleOrderItemsReduced() {
                if (this.searchText?.length > 0) {
                    return this.possibleOrderItems.filter((item) => {
                        return item.productName.toLowerCase().includes(this.searchText.toLowerCase());
                    });
                }
                return this.possibleOrderItems;
            }
        },
        methods: {
            getBundleIdFromOrderItemId(item) {
                let bundleNode = this.bundleTrees.find((tree) => {
                    return tree.orderItemId === item.orderItemId;
                });
                if (bundleNode) {
                    return bundleNode.bundleId;
                } else {
                    return null;
                }
            },
            getBundleId(item) {
                const bundlePath = this.$store.getters['bundles/getPathFromOrderItemId'](item?.orderItemId);
                return bundlePath ? bundlePath[0].bundleId : this.getBundleIdFromOrderItemId(item);
            },
            isSelected(item) {
                const bundleId = this.getBundleId(item);
                return this.selectedBundles.indexOf(bundleId) !== -1;
            },
            selectBundle(val, item) {
                const bundleId = this.getBundleId(item);
                this.$emit('select-bundle', val, bundleId);
            },
            selectAll(val) {
                this.group.items.forEach((item) => {
                    this.selectBundle(val, item);
                });
            },
            showPicker() {
                this.selectedItems = new Set;
                this.pickerVisible = true;

            },
            closePicker() {
                this.selectedItems = new Set;
                this.pickerVisible = false;
            },
            toggleSelectedItems(orrderItemId) {
                if (this.selectedItems.has(orrderItemId)) {
                    this.selectedItems.delete(orrderItemId);
                } else {
                    this.selectedItems.add(orrderItemId);
                }
            },
            updateBudgetSelection() {
                this.$store.dispatch('basket/updateBudgetUsedBulk', {
                    basketItemIdList: Array.from(this.selectedItems),
                    budgetId: this.group.budget ? this.group.budget.id : null
                }).then(() => {
                    this.closePicker();
                }).catch((error) => {
                    console.error(error);
                });
            }
        }
    };
</script>

<style lang="scss" scoped>
    .picker-checkbox {
        // checkbox is centered to the product image
        margin-top: 31px;
    }
    .picker-row-item {
        position: relative;

        .picker-row-item {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            cursor: pointer;
        }

        + .picker-row-item {
            border-top: 1px solid var(--bs-gray-middle);
        }
    }
</style>
