import shopApi from "../../api/shop";

const RESULT_LIMIT = 999;
const CHUNK_SIZE = 25;

//state
const state = {
    searchStr: '',

    offset: 0,
    limit: CHUNK_SIZE,

    tagIds: [],
    tags: {},
    tagCombineLogic: 'AND',

    resultDisplayFilter: null,

    currentlySearching: false,

    productCategorySearchRunning: false,
    productCategoryResult: [],
    productCategoryCount: 0,
    productCategoryLoadMore: false,

    productSearchRunning: false,
    productResult: [],
    productCount: 0,
    productLoadMore: false,

    assetLibrarySearchRunning: false,
    assetLibraryResult: [],
    assetLibraryCount: 0,
    assetGroupResult: [],

    assetEntrySearchRunning: false,
    assetEntryResult: [],
    assetEntryCount: 0,
    assetEntryLoadMore: false,

    searchables: {
        productCategory: false,
        product: false,
        assetLibrary: false,
        assetEntry: false,
        tags: false
    },
    searchableHierachie: ['product', 'productCategory', 'assetLibrary', 'assetEntry']
};

// getters
const getters = {
    pageSizes() {
        return [CHUNK_SIZE, 50, 100, 250, RESULT_LIMIT];
    },

    tagCombineLogic(state) {
        return state.tagCombineLogic;
    },
    tags(state) {
        return state.tags;
    },
    tagIds(state) {
        return state.tagIds;
    },
    allowProductCategorySearch(state) {
        return !state.tagIds.length && state.searchables.productCategory;
    },
    allowProductSearch(state) {
        return state.searchables.product;
    },
    allowAssetLibrarySearch(state) {
        return !state.tagIds.length && state.searchables.assetLibrary;
    },
    allowAssetEntrySearch(state) {
        return !state.tagIds.length && state.searchables.assetEntry;
    },
    allowTagsSearch(state) {
        return state.searchables.tags;
    },
    productCategoryResult(state) {
        return state.productCategoryResult;
    },
    productResult(state) {
        return state.productResult;
    },
    productCategoryLoadMore(state) {
        return state.productCategoryLoadMore;
    },
    productLoadMore(state) {
        return state.productLoadMore;
    },
    assetEntryLoadMore(state) {
        return state.assetEntryLoadMore;
    },
    assetLibraryResult(state) {
        return state.assetLibraryResult;
    },
    assetGroupResult(state) {
        return state.assetGroupResult;
    },
    assetEntryResult(state) {
        return state.assetEntryResult;
    },
    searchAllowed(state) {
        return Object.values(state.searchables).reduce((allowed, entry) => allowed || entry, false);
    },
    searchableWithHighestPrio: (state) => {
        return state.searchableHierachie.reduce((finding, searchable) => {
            if (finding) {
                return finding;
            }

            return state.searchables[searchable] ? searchable : null;
        }, null);
    },

    productCategorySearchParams(state, getters, rootState, rootGetters) {
        return {
            rootId: rootGetters['shop/productRootFolderId'],
            offset: 0,
            limit: CHUNK_SIZE,
            searchText: state.searchStr || ''
        };
    },
    productSearchParams(state, getters, rootState, rootGetters) {
        const params = {
            categoryId: rootGetters['shop/productRootFolderId'],
            recursive: true,
            searchText: state.searchStr,
            entityIds: null,
            offset: 0,
            limit: CHUNK_SIZE,
            tagIds: null,
            tagIdOperation: null
        };

        if (state.tagIds.length > 0) {
            params.tagIds = state.tagIds;
            params.tagIdOperation = state.tagCombineLogic;
        }

        return params;
    },
    assetCategorySearchParams(state) {
        return {
            searchText: state.searchStr || ''
        };
    },
    assetSearchParams(state, getters, rootState, rootGetters) {
        return {
            categoryId: rootGetters['shop/assetLibraryRootFolderId'],
            offset: 0,
            limit: CHUNK_SIZE,
            searchText: state.searchStr
        };
    },
};

// actions
const actions = {
    setSearchStr({commit, dispatch}, searchStr) {
        commit('SET_SEARCH_STR', searchStr);
        commit('SET_OFFSET', 0);
        commit('SET_LIMIT', CHUNK_SIZE);
        if (searchStr !== null) {
            dispatch('search');
        }
    },
    resetOffset({commit}) {
        commit('SET_OFFSET', 0);
    },
    addTagId({commit, dispatch}, tagId) {
        commit('ADD_TAG_ID', tagId);

        this.dispatch('tags/find', {
            id: tagId
        }, true).then((result) => {
            commit('ADD_TAG', result);
        });

        dispatch('search');
    },
    removeTagId({commit, dispatch}, tagId) {
        commit('REMOVE_TAG_ID', tagId);
        dispatch('search');
    },
    clearTags({commit}) {
        commit('CLEAR_TAGS');
    },
    setTagCombineLogic({commit, dispatch}, value) {
        commit('SET_TAG_COMBINE_LOGIC', value);
        dispatch('search');
    },
    search({commit, dispatch, state, getters}) {
        commit('SET_SEARCHING', true);
        dispatch('setResultFilter', getters['searchableWithHighestPrio']);

        shopApi.getSearchCounts(
            getters.productCategorySearchParams,
            getters.productSearchParams,
            getters.assetCategorySearchParams,
            getters.assetSearchParams,
        ).then(counts => {
            commit('SET_PRODUCTCATEGORY_COUNT', counts.productCategory);
            commit('SET_PRODUCTCATEGORY_LOAD_MORE', counts.productCategory >= CHUNK_SIZE);
            commit('SET_PRODUCT_COUNT', counts.product === -1 ? getters.productSearchParams.limit : counts.product);
            commit('SET_PRODUCT_LOAD_MORE', counts.product === -1 || counts.product >= CHUNK_SIZE);
            commit('SET_ASSETLIBRARY_COUNT', counts.assetCategory + counts.assetLibrary);
            commit('SET_ASSETENTRY_COUNT', counts.asset);
            commit('SET_ASSETENTRY_LOAD_MORE', counts.asset >= CHUNK_SIZE);
        })
        .finally(() => {
            if (state.tagIds.length > 0) {
                dispatch('setResultFilter', 'product');
            } else {
                for (const searchable of state.searchableHierachie) {
                    if (state.searchables[searchable] && (
                        (
                            searchable === 'assetLibrary' &&
                            (state.assetGroupResult.length || state.assetLibraryResult.length)
                        ) ||
                        state[searchable + 'Count']
                    )) {
                        dispatch('setResultFilter', searchable);
                        break;
                    }
                }
            }
            commit('SET_SEARCHING', false);
        });
    },
    searchProductCategories({commit, dispatch, getters}, {offset, limit}) {
        if (getters['allowProductCategorySearch']) {
            commit('SET_PRODUCTCATEGORY_SEARCH_RUNNING', true);

            return dispatch(
                'productCategories/filterBy',
                {...getters.productCategorySearchParams, limit, offset},
                {root: true}
            ).then(result => {
                if (state.resultDisplayFilter === 'productCategory') {
                    commit('SET_OFFSET', offset);
                    commit('SET_LIMIT', limit);
                }
                commit('SET_PRODUCTCATEGORY_RESULT', result);
                commit('SET_PRODUCTCATEGORY_COUNT', offset + result.length);

                let count = -1;

                if (result.length && result.length % limit === 0) {
                    commit('SET_PRODUCTCATEGORY_LOAD_MORE', true);
                } else {
                    commit('SET_PRODUCTCATEGORY_LOAD_MORE', false);
                    count = state.productCategoryCount;
                }

                return {list: result, count: count};
            }).catch(() => {
                commit('SET_PRODUCTCATEGORY_RESULT',  []);
            }).finally(() => {
                commit('SET_PRODUCTCATEGORY_SEARCH_RUNNING', false);
            });
        }
    },
    searchProducts({commit, dispatch, getters, state}, {limit, offset}) {
        if (getters['allowProductSearch']) {
            commit('SET_PRODUCT_SEARCH_RUNNING', true);

            const defaultParams = getters.productSearchParams;

            let params = {
                filterCriteria: {
                    categoryId: defaultParams.categoryId,
                    recursive: defaultParams.recursive,
                    searchText: defaultParams.searchText,
                    entityIds: defaultParams.entityIds,
                    tagIds: defaultParams.tagIds,
                    tagIdOperation: defaultParams.tagIdOperation
                },
                offset: offset,
                limit: limit,
            };

            return dispatch('products/getProducts',
                params,
                {root: true}
            ).then(result => {
                if (state.resultDisplayFilter === 'product') {
                    commit('SET_OFFSET', offset);
                    commit('SET_LIMIT', limit);
                }
                commit('SET_PRODUCT_RESULT', result.list);
                commit('SET_PRODUCT_COUNT', offset + result.list.length);

                let count = state.productResult.length;

                if (count && count % limit === 0 && count !== result.count) {
                    commit('SET_PRODUCT_LOAD_MORE', true);
                } else {
                    commit('SET_PRODUCT_LOAD_MORE', false);
                }

                return result;

            }).catch(() => {
                commit('SET_PRODUCT_RESULT', []);
            }).finally(() => {
                commit('SET_PRODUCT_SEARCH_RUNNING', false);
            });
        }
    },
    searchAssetLibraries({commit, dispatch, getters}) {
        if (getters['allowAssetLibrarySearch']) {
            commit('SET_ASSETLIBRARY_SEARCH_RUNNING', true);

            return dispatch(
                'assetCategories/searchStyleguidesAndCategories',
                getters.assetCategorySearchParams,
                {root: true}
            ).then((result) => {
                commit('SET_ASSETLIBRARY_RESULT', result.styleguides);
                commit('SET_ASSETGROUP_RESULT', result.categories);

                const count = (result.categories?.length ?? 0) + (result.styleguides?.length ?? 0);

                commit('SET_ASSETLIBRARY_COUNT', count);

                return {
                    list: [...result.styleguides, ...result.categories],
                    count
                };
            }).catch(() => {
                commit('SET_ASSETLIBRARY_RESULT',  []);
                commit('SET_ASSETGROUP_RESULT',  []);
            }).finally(() => {
                commit('SET_ASSETLIBRARY_SEARCH_RUNNING', false);
            });
        }
    },
    searchAssetEntries({commit, dispatch, getters}, {limit, offset}) {
        if (getters['allowAssetEntrySearch']) {
            commit('SET_ASSETENTRY_SEARCH_RUNNING', true);

            return dispatch(
                'assets/searchEntries', getters.assetSearchParams, {root: true}
            ).then(result => {
                if (state.resultDisplayFilter === 'assetEntries') {
                    commit('SET_OFFSET', offset);
                    commit('SET_LIMIT', limit);
                }
                commit('SET_ASSETENTRY_RESULT', result);
                commit('SET_ASSETENTRY_COUNT', offset + result.length);

                let count = -1;

                if (result.length && result.length % limit === 0) {
                    commit('SET_ASSETENTRY_LOAD_MORE', true);
                } else {
                    commit('SET_ASSETENTRY_LOAD_MORE', false);
                    count = state.assetEntryCount;
                }

                return {list: result, count: count};
            }).catch(() => {
                commit('SET_ASSETENTRY_RESULT',  []);
            }).finally(() => {
                commit('SET_ASSETENTRY_SEARCH_RUNNING', false);
            });
        }
    },
    setSearchables({commit}, data) {
        commit('SET_SEARCHABLES', data);
    },
    setResultFilter({commit}, data) {
        commit('SET_RESULT_FILTER', data);
    },
    localToggleFavorite({commit}, productId) {
        commit('TOGGLE_FAVORITE', productId);
    }
};

// mutations
const mutations = {
    RESET(state) {
        state.searchStr = '';
        state.offset = 0;
        state.limit = CHUNK_SIZE;
        state.tagIds = [];
        state.tags = {};
        state.tagCombineLogic = 'AND';
        state.resultDisplayFilter = null;
        state.productCategorySearchRunning = false;
        state.productCategoryResult = [];
        state.productCategoryCount = 0;
        state.productSearchRunning = false;
        state.productResult = [];
        state.productCount = 0;
        state.assetLibrarySearchRunning = false;
        state.assetLibraryResult = [];
        state.assetGroupResult = [];
        state.assetLibraryCount = 0;
        state.assetEntrySearchRunning = false;
        state.assetEntryResult = [];
        state.assetEntryCount = 0;
        state.searchables = {
            productCategory: false,
            product: false,
            assetLibrary: false,
            assetEntry: false,
            tags: false
        };
        state.currentlySearching = false;
    },
    RESET_RESULTS(state) {
        state.productCategoryResult = [];
        state.productCategoryCount = 0;
        state.productResult = [];
        state.productCount = 0;
        state.assetLibraryResult = [];
        state.assetGroupResult = [];
        state.assetLibraryCount = 0;
        state.assetEntryResult = [];
        state.assetEntryCount = 0;
    },
    SET_SEARCH_STR(state, searchStr) {
        state.searchStr = searchStr;
    },
    SET_OFFSET(state, offset) {
        state.offset = offset;
    },
    SET_LIMIT(state, limit) {
        state.limit = limit;
    },
    SET_SEARCHING(state, status) {
        state.currentlySearching = status;
    },
    CLEAR_TAGS(state) {
        state.tagIds = [];
        state.tags = {};
        state.tagCombineLogic = 'AND';
    },
    ADD_TAG_ID(state, tagId) {
        if (!state.tagIds.includes(tagId)) {
            state.tagIds.push(tagId);
        }
    },
    REMOVE_TAG_ID(state, tagId) {
        if (state.tagIds.includes(tagId)) {
            state.tagIds.splice(state.tagIds.indexOf(tagId), 1);
        }
        delete state.tags[tagId];
    },
    ADD_TAG(state, tag) {
        state.tags[tag.id] = tag;
    },
    SET_TAG_COMBINE_LOGIC(state, value) {
        state.tagCombineLogic = value;
    },
    SET_RESULT_FILTER(state, resultFilter) {
        state.resultDisplayFilter = resultFilter;
    },
    SET_PRODUCTCATEGORY_SEARCH_RUNNING(state, isRunning) {
        state.productCategorySearchRunning = isRunning;
    },
    SET_PRODUCTCATEGORY_RESULT(state, result) {
        state.productCategoryResult = result;
    },
    SET_PRODUCTCATEGORY_COUNT(state, count) {
        state.productCategoryCount = count;
    },
    SET_PRODUCT_SEARCH_RUNNING(state, isRunning) {
        state.productSearchRunning = isRunning;
    },
    SET_PRODUCT_RESULT(state, result) {
        state.productResult = result;
    },
    SET_PRODUCT_COUNT(state, count) {
        state.productCount = count;
    },
    SET_ASSETLIBRARY_SEARCH_RUNNING(state, isRunning) {
        state.assetLibrarySearchRunning = isRunning;
    },
    SET_ASSETLIBRARY_RESULT(state, result) {
        state.assetLibraryResult = Array.isArray(result) ? result : [];
    },
    SET_ASSETGROUP_RESULT(state, result) {
        state.assetGroupResult = Array.isArray(result) ? result : [];
    },
    SET_ASSETLIBRARY_COUNT(state, count) {
        state.assetLibraryCount = count;
    },
    SET_ASSETENTRY_SEARCH_RUNNING(state, isRunning) {
        state.assetEntrySearchRunning = isRunning;
    },
    SET_ASSETENTRY_RESULT(state, result) {
        state.assetEntryResult = result;
    },
    SET_ASSETENTRY_COUNT(state, count) {
        state.assetEntryCount = count;
    },
    SET_SEARCHABLES(state, searchables) {
        state.searchables = searchables;
    },
    TOGGLE_FAVORITE(state, productId) {
        state.productResult.forEach(prod => {
            if (productId === prod.id) {
                prod.isFavorite = !prod.isFavorite;
            }
        });
    },
    SET_PRODUCTCATEGORY_LOAD_MORE(state, value) {
        state.productCategoryLoadMore = value;
    },
    SET_PRODUCT_LOAD_MORE(state, value) {
        state.productLoadMore = value;
    },
    SET_ASSETENTRY_LOAD_MORE(state, value) {
        state.assetEntryLoadMore = value;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
