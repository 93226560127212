<template>
    <div
        type="button"
        class="btn"
        :class="{
            'link-primary': variant === 'primary',
            'link-text': variant === 'text',
            'disabled': disabled
        }"
    >
        <slot name="default"></slot>
    </div>
</template>

<script>
    export default {
        name: 'NdxButtonLink',
        props: {
            variant: {
                type: String,
                default: 'primary',
                validator: function (value) {
                    return ['primary', 'text'].indexOf(value) !== -1;
                }
            },
            disabled: {
                type: Boolean
            }
        }
    };
</script>
