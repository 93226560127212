<template>
    <div
        ref="stickyHeader"
        class="d-flex justify-content-between align-items-end gap-3 mb-3"
        :class="{sticky: sticky}"
    >
        <div class="page-label">
            <NdxBreadcrumbs
                :breadcrumbs="breadcrumbs"
                :active-route-callback="activeRouteCallback"
            />
            {{ navName }}
        </div>
        <div class="d-flex gap-4 align-items-center">
            <slot name="default"></slot>
            <NdxDropdown
                v-if="!hideViewMode"
                class="view-dropDown roundedBorder"
                :options="viewModeOptionsComputed"
            >
                <div class="d-flex align-items-center gap-2">
                    <NdxIcon icon="eye-visible" size="m"/>
                    <span>{{ $t('label.viewMode') }}</span>
                </div>
            </NdxDropdown>
            <NdxButton
                v-if="!hideFilter"
                variant="secondary narrow"
                :class="{info: activeFilter}"
                @click="$emit('update:showFilter', true)"
                :disabled="filterBtnDisabled"
            >
                <template #icon>
                    <NdxIcon icon="filter" size="m"/>
                </template>
                <template #default>{{ $t('label.filterHeadline') }}</template>
            </NdxButton>
        </div>
    </div>
</template>

<script>
    import NdxDropdown from "./NdxDropdown.vue";
    import NdxBreadcrumbs from "./NdxBreadcrumbs.vue";
    import NdxIcon from "./NdxIcon.vue";
    import NdxButton from "./NdxButton.vue";
    import lodashThrottle from 'lodash/throttle';

    export default {
        name: 'NdxPageHeader',
        components: {NdxButton, NdxIcon, NdxBreadcrumbs, NdxDropdown},
        props: {
            viewModeStorageKey: {
                type: String
            },
            hideViewMode: {
                type: Boolean
            },
            viewModeOptions: {
                type: Array,
                default: function () {
                    return [];
                }
            },
            viewMode: {
                type: String,
                default: function () {
                    return 'card';
                }
            },

            navName: {
                type: String
            },

            breadcrumbs: {
                type: Array,
                default: function () {
                    return [];
                }
            },
            activeRouteCallback: {
                type: Function
            },

            hideFilter: {
                type: Boolean
            },
            filterBtnDisabled: {
                type: Boolean
            },
            activeFilter: {
                type: Boolean
            },
            showFilter: {
                type: Boolean
            },
            sticky: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                watchStickyState: null
            };
        },
        emits: ['update:viewMode', 'update:showFilter'],
        computed: {
            viewModeOptionsComputed() {
                if (this.viewModeOptions.length > 0) {
                    return this.viewModeOptions;
                }

                return [{
                    highlighted: this.viewMode === 'card',
                    text: this.$t('btn.view-card'),
                    value: 'card',
                    click: () => this.setViewMode('card')
                }, {
                    highlighted: this.viewMode === 'list',
                    text: this.$t('btn.view-list'),
                    value: 'list',
                    click: () => this.setViewMode('list')
                }];
            }
        },
        created() {
            if (this.viewModeStorageKey?.length > 0) {
                const storeEntry = window.localStorage.getItem(this.viewModeStorageKey);
                if (storeEntry) {
                    if (this.getViewModeItem(storeEntry) !== null) {
                        this.setViewMode(storeEntry);
                    }
                }
            }
        },
        mounted() {
            this.watchStickyState = lodashThrottle(this.determineStickyState, 100);
            window.addEventListener('scroll', this.watchStickyState);
        },
        beforeUnmount() {
            window.removeEventListener('scroll', this.watchStickyState);
        },
        methods: {
            determineStickyState() {
                if (!this.sticky) {
                    return;
                }
                const element = this.$refs.stickyHeader;
                const computedStyle = window.getComputedStyle(element);
                const stickyElementTop = parseInt(computedStyle.top, 10);
                const currentTop = element.getBoundingClientRect().top;
                element.classList.toggle('stuck', currentTop <= stickyElementTop);
            },
            getViewModeItem(mode) {
                const list = this.viewModeOptionsComputed.filter(item => item.value === mode);
                return list.length > 0 ? list[0] : null;
            },
            setViewMode(viewMode) {
                this.$emit('update:viewMode', viewMode);

                if (this.viewModeStorageKey?.length > 0 && this.getViewModeItem(viewMode) !== null) {
                    window.localStorage.setItem(this.viewModeStorageKey, viewMode);
                }
            }
        }
    };
</script>

<style scoped lang="scss">
.sticky {
    position: sticky;
    top: 0;
    z-index: 2;
    transition: padding 0.3s ease-in-out;
    &.stuck {
        background-color: white;
        border-bottom: 2px solid var(--bs-primary);
        box-shadow: 0 16px 4px white;
        padding: 16px 0;
    }
}
</style>
