<template>
    <div class="detail-page">
        <NdxPageHeader
            class="detail-page-breadcrumb"
            hide-filter
            hide-view-mode
            :breadcrumbs="breadcrumbs"
            :active-route-callback="gotoOverview"
            :nav-name="breadcrumbs[breadcrumbs.length - 1].label"
        />

        <div class="detail-page-back mb-3 d-md-none">
            <NdxButton @click="$router.go(-1)" class="d-inline-flex">
                <template #icon>
                    <NdxIcon icon="drop-left" size="m"/>
                </template>
                {{ $t('btn.back') }}
            </NdxButton>
        </div>
        <div class="detail-page-top" ref="top">
            <slot name="top"/>
        </div>
        <div class="detail-page-content">
            <div class="detail-page-visual">
                <slot name="visual">
                    <NdxImage class="default-visual"/>
                </slot>
            </div>
            <div class="detail-page-default">
                <div class="detail-page-headline">
                    <slot name="headline"/>
                </div>
                <slot name="default"/>
            </div>
        </div>
        <div class="detail-page-addons">
            <slot name="additions"/>
        </div>
    </div>
</template>

<script>
    import NdxImage from "../../components/library/NdxImage.vue";
    import NdxIcon from "../../components/library/NdxIcon.vue";
    import NdxButton from "../../components/library/NdxButton.vue";
    import NdxPageHeader from "../../components/library/NdxPageHeader.vue";

    export default {
        name: 'DetailPage',
        components: {NdxPageHeader, NdxButton, NdxIcon, NdxImage},
        props: {
            breadcrumb: {
                type: String,
                default: ''
            }
        },
        computed: {
            breadcrumbs() {
                let list = [];

                if (this.$route.name.toLowerCase().includes('product')) {
                    list.push(...this.getProductsBreadcrumbs());
                } else if (this.$route.name.toLowerCase().includes('asset')) {
                    list.push(...this.getAssetsBreadcrumbs());
                } else if (this.$route.name === 'WorkflowsTaskDetail') {
                    list.push(...this.getWorkflowTaskBreadcrumbs());
                } else if (['WorkflowsInstanceDetail', 'WorkflowsInstanceCompletedDetail'].includes(this.$route.name)) {
                    list.push(...this.getWorkflowInstanceBreadcrumbs());
                }

                list.push({
                    label: this.breadcrumb ? this.breadcrumb : this.$t('breadcrumb.' + this.$route.name),
                    click: null
                });

                return list;
            },
            currentWgc() {
                const wgcId = +this.$route.params?.id || 0;
                return this.$store.getters['shop/getWgc'](wgcId);
            },
            categoryId() {
                return this.$route.params?.categoryId || null;
            },
            rootCategoryId() {
                if (this.$route.name.toLowerCase().includes('product')) {
                    return this.currentWgc?.config?.productCategoryRootId ||
                        this.$store.getters['shop/productRootFolderId'];
                } else if (this.$route.name.toLowerCase().includes('asset')) {
                    return this.$store.getters['shop/assetLibraryRootFolderId'];
                }
                return null;
            }
        },
        watch: {
            categoryId: {
                immediate: true,
                handler: function () {
                    if (+this.categoryId > 0 && +this.rootCategoryId > 0) {
                        if (this.$route.name.toLowerCase().includes('product')) {
                            this.$store.dispatch('productCategories/getParentList', {
                                categoryId: this.categoryId,
                                rootCategoryId: this.rootCategoryId
                            });
                        } else if (this.$route.name.toLowerCase().includes('asset')) {
                            this.$store.dispatch('assetCategories/getParentList', {
                                categoryId: this.categoryId,
                                rootCategoryId: this.rootCategoryId
                            });
                        }
                    }
                }
            }
        },
        methods: {
            gotoOverview() {
                let routePrams = this.$store.getters['shop/getWgc'](+this.$route.params.id);

                this.$router.push(routePrams.route);
            },
            getProductsBreadcrumbs() {
                if (this.categoryId && this.categoryId !== this.rootCategoryId) {
                    const parentList = this.$store.getters['productCategories/getParentList'](
                        this.categoryId, this.rootCategoryId
                    );
                    return this.buildList(
                        parentList,
                        'ProductsInCategory',
                        {pid: ''},
                    );
                }
                return [];
            },
            getAssetsBreadcrumbs() {
                if (this.categoryId && this.categoryId !== this.rootCategoryId) {
                    const parentList = this.$store.getters['assetCategories/getParentList'](
                        this.categoryId, this.rootCategoryId
                    );
                    return this.buildList(
                        parentList,
                        'AssetsInCategory',
                        {aid: ''},
                    );
                }
                return [];
            },
            buildList(parentList, routeName, defaultParams) {
                let list = [];

                if (parentList.length > 2) {
                    list.push({
                        label: '…'
                    });
                }
                for (let item of parentList.slice(-2)) {
                    list.push({
                        label: item.name,
                        click: () => {
                            this.$router.push({
                                name: routeName,
                                params: {
                                    ...defaultParams,
                                    id: this.currentWgc.route.params.id,
                                    categoryId: item.id,
                                }
                            });
                        }
                    });
                }

                return list;
            },
            getWorkflowTaskBreadcrumbs() {
                let list = [];
                const wgc = this.$store.getters['shop/getWgcByType']('workflowsTasks');
                if (wgc) {
                    list.push({
                        label: wgc.label,
                        click: () => this.$router.push(wgc.route)
                    });
                }
                return list;
            },
            getWorkflowInstanceBreadcrumbs() {
                let list = [];
                const wgc = this.$store.getters['shop/getWgcByType']('workflowsInstances');
                if (wgc) {
                    list.push({
                        label: wgc.label,
                        click: () => this.$router.push(wgc.route)
                    });
                }
                if (this.$route.name === 'WorkflowsInstanceDetail') {
                    list.push({
                        label: this.$t('label.workflow.workflowInstancesActive'),
                        click: () => {
                            this.$router.push({
                                name: 'WorkflowsInstancesTypeFiltered',
                                params: {
                                    id: wgc.route.params.id,
                                    taskType: 'active'
                                }
                            });
                        }
                    });
                } else if (this.$route.name === 'WorkflowsInstanceCompletedDetail') {
                    list.push({
                        label: this.$t('label.workflow.workflowInstancesCompleted'),
                        click: () => {
                            this.$router.push({
                                name: 'WorkflowsInstancesTypeFiltered',
                                params: {
                                    id: wgc.route.params.id,
                                    taskType: 'completed'
                                }
                            });
                        }
                    });
                }

                return list;
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "../../style/variables_ndx";
    @import "../../style/variables_bootstrap";
    @import "~bootstrap/scss/mixins/breakpoints";

    .detail-page {
        display: flex;
        flex-direction: column;

        .detail-page-top {
            margin-bottom: 16px;

            &:empty {
                display: none;
            }
        }

        .detail-page-content {
            display: flex;
            gap: 64px;
            margin-bottom: 32px;

            .detail-page-visual {
                max-width: 274px;
            }

            .detail-page-default {
                flex-grow: 1;
                min-width: 200px;

                .detail-page-headline {
                    @extend %font-h1;

                    font-weight: bold;
                    overflow: hidden;
                    overflow-wrap: anywhere;
                }
            }
        }
    }

    @include media-breakpoint-up(xl) {
        .detail-page {
            .detail-page-visual {
                max-width: 569px;
                min-width: 487px
            }
        }
    }

    @include media-breakpoint-down(lg) {
        .detail-page {
            .detail-page-content {
                flex-direction: column;
                gap: 16px;

                .detail-page-visual {
                    max-width: unset;
                    overflow: hidden;
                }

                .detail-page-default {
                    min-width: unset;
                }
            }
        }
    }

    :deep(.detail-page-visual) {
        aspect-ratio: 1;
        height: auto;
    }
</style>
