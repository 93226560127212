import { throbberRules } from "@utilities/throbberRules";
import { ndxRpc } from "../utils/ndxRpc";

const service = 'basket';

export default {
    getAllData() {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'getAllData', [], throbberRules.FULLSCREEN)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getBasketGroups() {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'getBasketGroups', [], throbberRules.FULLSCREEN)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    addToBasket(productId, quantity, productFeatureValues = null, campaignrunId = null,
                getFullBasket = false, desiredBudgetId = null
    ) {
        return new Promise((resolve, reject) => {
            ndxRpc(
                service,
                'add',
                [productId, quantity, productFeatureValues, campaignrunId, getFullBasket, desiredBudgetId],
                throbberRules.FULLSCREEN_SPINNER
            )
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    addToBasketWithExistingCustomization(productId, quantity, productFeatureValues, campaignrunId) {
        return new Promise((resolve, reject) => {
            ndxRpc(
                service,
                'addWithExistingCustomization',
                [productId, quantity, productFeatureValues, campaignrunId],
                throbberRules.FULLSCREEN
            )
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    updateBasketItem(basketItemId, quantity, productFeatureValues, desiredBudgetId) {
        return new Promise((resolve, reject) => {
            ndxRpc(
                service,
                'update',
                [basketItemId, quantity, productFeatureValues, desiredBudgetId],
                throbberRules.FULLSCREEN
            ).then((data) => {
                resolve(data);
            }).catch((error) => {
                reject(error);
            });
        });
    },

    moveBundleToBasket(bundleId) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'moveBundleToBasket', [bundleId], throbberRules.FULLSCREEN)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    moveBundleToWatchlist(bundleId, skipHydration) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'moveBundleToWatchlist', [bundleId, skipHydration], throbberRules.FULLSCREEN)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    removeBundle(bundleId, listType, skipHydration) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'removeBundle', [bundleId, listType, skipHydration], throbberRules.FULLSCREEN)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    getBasket() {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'find', [], throbberRules.FULLSCREEN)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    getCheckoutData() {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'getCheckoutData', [], throbberRules.FULLSCREEN)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    order() {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'order', [], throbberRules.FULLSCREEN)
                .then((orderId) => {
                    resolve(orderId);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    updateQuantity(basketItemId, quantity) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'updateQuantity', [basketItemId, quantity], throbberRules.FULLSCREEN)
                .then((newBasketFindResult) => {
                    resolve(newBasketFindResult);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    updateBudgetUsed(basketItemId, budgetId, checkRemainingValue) {
        const params = [basketItemId, budgetId, checkRemainingValue];
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'updateBudgetUsed', params, throbberRules.FULLSCREEN)
                .then((newBasketFindResult) => {
                    resolve(newBasketFindResult);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    updateBudgetUsedBulk(basketItemIdList, budgetId, checkRemainingValue) {
        const params = [basketItemIdList, budgetId, checkRemainingValue];
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'updateBudgetUsedBulk', params, throbberRules.FULLSCREEN)
                .then((newBasketFindResult) => {
                    resolve(newBasketFindResult);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    getPrintContentBlocks() {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'getPrintContentBlocks', [], throbberRules.FULLSCREEN)
                .then((blocks) => {
                    resolve(blocks);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    getQuickCheckoutData() {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'getQuickCheckoutData', [], throbberRules.FULLSCREEN)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    setAddress(addressType, addressId) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'setAddress', [addressType, +addressId], throbberRules.FULLSCREEN)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    findBasketItem(basketItemId) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'findBasketItem', [basketItemId], throbberRules.FULLSCREEN)
                .then((item) => {
                    resolve(item);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    updateDeliveryOptionOrderItem(orderItemId, optionId) {
        return new Promise((resolve, reject) => {
            ndxRpc(
                service,
                'updateDeliveryOptionOrderItem',
                [+orderItemId, +optionId],
                throbberRules.FULLSCREEN
            )
                .then((result) => {
                    resolve(result);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    updateDeliveryOptionAddressItem(orderItemAddressId, optionId) {
        return new Promise((resolve, reject) => {
            ndxRpc(
                service,
                'updateDeliveryOptionAddressItem',
                [+orderItemAddressId, +optionId],
                throbberRules.FULLSCREEN
            )
                .then((result) => {
                    resolve(result);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    updateAddressComments(addressComments) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'updateAddressComments', [addressComments], throbberRules.FULLSCREEN)
                .then((checkoutData) => {
                    resolve(checkoutData);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    saveMultiAddress(multiAddressInfo) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'saveMultiAddress', [multiAddressInfo], throbberRules.FULLSCREEN)
                .then((result) => {
                    resolve(result);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    deactivateMultiAddress() {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'deactivateMultiAddress', [], throbberRules.FULLSCREEN)
                .then((result) => {
                    resolve(result);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    getMultiAddressDeliveryAddresses() {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'getMultiAddressDeliveryAddresses', [], throbberRules.FULLSCREEN)
                .then((checkoutData) => {
                    resolve(checkoutData);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    getWatchlist(filterCriteria, offset, limit) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'getWatchlist', [filterCriteria, +offset, +limit], throbberRules.FULLSCREEN)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    removeApproval(campaignRunId) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'removeApproval', [+campaignRunId], throbberRules.FULLSCREEN)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    getCheckoutCustomization(lang) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'getCheckoutCustomization', [lang], throbberRules.CONTENTAREA)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    saveCheckoutCustomization(data, lang) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'saveCheckoutCustomization', [data, lang], throbberRules.CONTENTAREA)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    findBundledOrderItems(bundleId, throbberRule = throbberRules.CONTENTAREA) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'findBundledOrderItems', [bundleId], throbberRule)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
};
