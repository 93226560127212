import { throbberRules } from "@utilities/throbberRules";
import { ndxRpc } from "../utils/ndxRpc";

const service = 'bundleConfigurator';

export default {
    add(productId, quantity, productFeatureValues = null, campaignrunId = null) {
        return new Promise((resolve, reject) => {
            ndxRpc(
                service,
                'add',
                [productId, quantity, productFeatureValues, campaignrunId],
                throbberRules.FULLSCREEN_SPINNER
            )
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    updateQuantity(basketItemId, quantity) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'updateQuantity', [basketItemId, quantity], throbberRules.FULLSCREEN)
                .then((newBasketFindResult) => {
                    resolve(newBasketFindResult);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    updateBudgetUsed(basketItemId, budgetId) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'updateBudgetUsed', [basketItemId, budgetId], throbberRules.FULLSCREEN)
                .then((newBasketFindResult) => {
                    resolve(newBasketFindResult);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    moveBundleToBasket(bundleId) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'moveBundleToBasket', [bundleId], throbberRules.FULLSCREEN)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
};
