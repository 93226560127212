import lodashMerge from "lodash/merge";
import bundleConfiguratorApi from '../../api/bundleConfiguratorApi';

//state
const state = {
    bundleTree: null,
    groups: []
};

// getters
const getters = lodashMerge({}, {});

// actions
const actions = {
    reset({commit}) {
        commit('RESET');
    },
    add({commit}, data) {
        return new Promise((resolve, reject) => {
            bundleConfiguratorApi.add(data.productId, data.quantity, data.productFeatureValues,
                data.campaignRunId)
                .then((result) => {
                    commit('SET_BUNDLE_TREE', result.bundleTrees ? result.bundleTrees[0] : null);
                    commit('SET_GROUPS', result.groups);
                    resolve(result);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    updateQuantity({commit}, data) {
        return new Promise((resolve, reject) => {
            bundleConfiguratorApi.updateQuantity(data.basketItemId, data.quantity)
                .then(async (result) => {
                    commit('SET_BUNDLE_TREE', result.bundleTrees ? result.bundleTrees[0] : null);
                    commit('SET_GROUPS', result.groups);
                    await resolve(result);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    updateBudgetUsed({commit}, data) {
        return new Promise((resolve, reject) => {
            bundleConfiguratorApi.updateBudgetUsed(data.basketItemId, data.budgetId)
                .then(async (result) => {
                    commit('SET_BUNDLE_TREE', result.bundleTrees ? result.bundleTrees[0] : null);
                    commit('SET_GROUPS', result.groups);
                    await resolve(result);
                })
                .catch((error) => {
                    reject(error);
                });
        });

    },
    moveBundleToBasket(ctx, data) {
        return new Promise((resolve, reject) => {
            bundleConfiguratorApi.moveBundleToBasket(data.bundleId)
                .then((newBasketData) => {
                    resolve(newBasketData);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    setBundleTree({commit}, tree) {
        commit('SET_BUNDLE_TREE', tree);
    },
    setGroups({commit}, groups) {
        commit('SET_GROUPS', groups);
    },
};

// mutations
const mutations = {
    RESET(state) {
        state.bundleTree = null;
        state.groups = [];
    },
    SET_BUNDLE_TREE(state, tree) {
        state.bundleTree = tree;
    },
    SET_GROUPS(state, groups) {
        state.groups = groups;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
