<template>
    <template v-for="(group, grpIdx) in filteredGroups(groups)" :key="grpIdx">
        <div class="roundedBorder">
            <div
                v-if="group.name?.length > 0"
                class="group-label"
            >{{ group.name }}</div>
            <div
                v-for="(entry, idx) in getEntries(group)"
                :key="grpIdx+'_'+idx"

                class="d-flex justify-content-between gap-3"
                :class="{'border-top': idx !== 0}"
            >
                <DataItem
                    :key="entry.id"
                    :item="buildItem(entry)"
                    type="checkoutAttribute"
                    :model-value="entry"
                    :readonly="readonly"
                    opened
                    allow-html-desc
                />
            </div>

            <div v-if="group.groups && filteredGroups(group.groups).length > 0">
                <CheckoutCustomizationFields
                    :groups="filteredGroups(group.groups)"
                    :readonly="readonly"
                    :is-order-view="isOrderView"
                />
            </div>
        </div>
    </template>
</template>

<script>

    import DataItem from "../../../myAccount/dataItem/DataItem";
    import lodashClone from "lodash/cloneDeep";

    export default {
        name: "CheckoutCustomizationFields",
        components: {DataItem},
        props: {
            groups: {
                type: Array,
                required: true
            },
            isOrderView: {
                type: Boolean,
                default: false
            },
            readonly: {
                type: Boolean
            }
        },
        methods: {
            filteredGroups(groups) {
                let list = [];
                groups.forEach((subGroup) => {
                    if (this.getEntries(subGroup).length > 0 || this.getSubEntries(subGroup).length > 0) {
                        list.push(subGroup);
                    }
                });
                return list;
            },
            getSubEntries(group) {
                let entries = this.getEntries(group);
                group.groups.forEach((subGroup) => {
                    entries.push(...this.getSubEntries(subGroup));
                });
                return entries;
            },
            getEntries(group) {
                return group.entries.filter(entry => entry.value !== null);
            },
            buildItem(entry) {
                let tmpEntry = lodashClone({...entry, readonly: true});

                if (this.isOrderView) {
                    let displayOptions = [];

                    if (entry.type === 'checkbox') {
                        displayOptions = [{
                            key: 0,
                            value: entry.valuesTranslated
                        }];
                    }
                    if (entry.type === 'select') {
                        displayOptions = [{
                            key: entry.value,
                            value: entry.valuesTranslated
                        }];
                    }
                    if (entry.type === 'selectMulti') {
                        displayOptions = [];
                        if ('values' in entry && entry.values &&
                            'value' in entry.values && entry.values.value
                        ) {
                            if (Array.isArray(entry.values.value)) {
                                entry.values.value.forEach((value, idx) => {
                                    displayOptions.push({
                                        key: value,
                                        value: entry.valuesTranslated?.value[idx]
                                    });
                                });
                            }
                        } else if (typeof entry.values.value === 'string') {
                            displayOptions.push({
                                key: entry.values.value,
                                value: entry.valuesTranslated?.value
                            });
                        }
                    }
                    if (entry.type === 'file' && tmpEntry.value) {
                        tmpEntry.value.cacheId = tmpEntry.value.filename;
                    }

                    tmpEntry.attributeDef = {
                        valueFormatType: entry.type,
                        displayOptions: displayOptions,
                        defaultValue: entry.value
                    };

                    tmpEntry.value = ['input', 'text'].includes(entry.type) && 'valuesTranslated' in tmpEntry ?
                        tmpEntry.valuesTranslated :
                        tmpEntry.value;
                }
                return tmpEntry;
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "../../../../style/variables_ndx";

    .group-label {
        @extend %font-h4 ;
        padding-bottom: 8px;
        border-bottom: 1px solid var(--bs-gray-middle);
    }

    :deep(.myAccount-data-item) {
        flex-grow: 1;

        .control {
            display: flex;
            flex-direction: row;
            gap: 1rem;
            flex-grow: 1;
            justify-content: space-between;

            .value-wrapper > svg {
                display: none;
            }
        }

        .editor {
            .description {
                @extend %font-body2;

                margin-top: 16px;
            }

            .dataItemEditor-wrapper {
                @extend %font-body2;

                margin-top: 16px;
            }
        }
    }
</style>
