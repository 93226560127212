<template>
    <div class="progressRoot">
        <div class="progressBar" :style="computedWidth"/>
        <div class="percentage d-flex align-items-center justify-content-center">
            <div class="value">{{ percentage }}<span class="unit">%</span></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'NdxProgress',
        props: {
            /**
             * the total number
             */
            max: {
                type: Number,
                required: true
            },
            /**
             * the current number
             */
            current: {
                type: Number,
                required: true
            }
        },
        computed: {
            percentage() {
                let _current = this.current < 0 ? 0 : this.current;
                let _max = this.max < _current ? (_current + 1) : this.max;
                return Math.round((100 * _current / _max) || 0);
            },
            computedWidth() {
                return 'width: ' + this.percentage + '%;';
            }
        }
    };
</script>

<style scoped lang="scss">
    .progressRoot {
        width: 100%;
        height: 24px;
        border-radius: 12px;
        position: relative;
        border: 1px solid var(--bs-primary);
        overflow: hidden;

        .progressBar {
            background-color: var(--bs-primary);
            height: 100%;
            position: absolute;
            bottom: 0;
        }

        .percentage {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;

            .value {
                font-size: 14px;
            }

            .unit {
                font-size: 14px;
            }
        }
    }
</style>
