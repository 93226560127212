<template>
    <Group :group="group" v-if="items.length">
        <template #group-items>
            <NdxCheckbox
                v-if="bulkModeActive"
                :label="$t('btn.selectAll')"
                class="multiSelector"
                @update:modelValue="(val) => this.selectAll(val)"
            />
            <template v-for="item in items" :key="item.orderItemId">
                <div class="bundleItemContainer d-flex">
                    <NdxCheckbox
                        v-if="bulkModeActive"
                        :model-value="isSelected(item)"
                        @update:model-value="(val) => selectBundle(val, item)"
                    />
                    <div class="flex-fill">
                        <BasketGroupItem
                            :item="item"
                            @refresh-basket="() => $emit('refresh-basket')"
                        />
                    </div>
                    <div
                        v-if="bulkModeActive"
                        class="clickCatcher"
                        :class="{selected: isSelected(item)}"
                        @click="selectBundle(!isSelected(item), item)"
                    ></div>
                </div>
            </template>
        </template>
    </Group>
</template>

<script>
    import BasketGroupItem from "./BasketGroupItem";
    import Group from "../../checkout/steps/parts/Group";
    import NdxCheckbox from "../../library/formElements/NdxCheckbox.vue";
    import {mapState} from "vuex";

    export default {
        name: "BasketVendorGroup",
        components: {NdxCheckbox, Group, BasketGroupItem},
        emits: ['refresh-basket', 'select-bundle'],
        props: {
            group: {
                type: Object,
                required: true
            },
            bulkModeActive: {
                type: Boolean
            },
            selectedBundles: {
                type: Array
            }
        },
        computed: {
            ...mapState({
                bundleTrees: state => state.basket.basket.bundleTrees
            }),
            items() {
                return this.group.items.filter(item => item.quantity > 0);
            }
        },
        methods: {
            getBundleIdFromOrderItemId(item) {
                let bundleNode = this.bundleTrees.find((tree) => {
                    return tree.orderItemId === item.orderItemId;
                });
                if (bundleNode) {
                    return bundleNode.bundleId;
                } else {
                    return null;
                }
            },
            getBundleId(item) {
                const bundlePath = this.$store.getters['bundles/getPathFromOrderItemId'](item?.orderItemId);
                return bundlePath ? bundlePath[0].bundleId : this.getBundleIdFromOrderItemId(item);
            },
            isSelected(item) {
                const bundleId = this.getBundleId(item);
                return this.selectedBundles.indexOf(bundleId) !== -1;
            },
            selectBundle(val, item) {
                const bundleId = this.getBundleId(item);
                this.$emit('select-bundle', val, bundleId);
            },
            selectAll(val) {
                this.items.forEach((item) => {
                   this.selectBundle(val, item);
                });
            }
        }
    };
</script>
