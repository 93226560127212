<template>
    <div class="ndx-form-element ndx-search">
        <NdxIcon icon="search" size="m"/>
        <input
            type="text"
            :placeholder="$t('label.doSearch')"
            :value="modelValue"
            :readonly="disabled"
            :disabled="disabled"
            @input="(evt) => $emit('update:model-value', evt.target.value)"
            @keyup.enter="(evt) => $emit('on-key-enter', evt.target.value)"
        />
        <div
            v-if="modelValue && removable"
            class="removable"
            @click="$emit('on-remove-value')">x</div>
    </div>
</template>

<script>
    import NdxIcon from "../NdxIcon";

    export default {
        name: 'NdxSearchInput',
        components: {NdxIcon},
        emits: ['on-key-enter', 'update:model-value', 'on-remove-value'],
        props: {
            modelValue: {
                type: String
            },
            disabled: {
                type: Boolean
            },
            removable: {
                type: Boolean
            }
        }
    };
</script>
