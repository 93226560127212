import lodashMerge from "lodash/merge";
import quickCheckoutApi from "../../api/quickCheckout";
import { basketGetters } from './base/basket';

//state
const state = {
    basket: {},
    basketItemCount: 0
};

// getters
const getters = lodashMerge({}, basketGetters, {});

// actions
const actions = {
    setProduct({commit}, data) {
        return new Promise((resolve, reject) => {
            quickCheckoutApi.setProduct(
                data.productId, data.quantity, data.productFeatureValues, data.desiredBudgetId
            ).then((result) => {
                commit('SET_BASKET', result.basket);
                resolve(result);
            }).catch((error) => {
                reject(error);
            });
        });
    },
    setAddress({commit}, data) {
        return new Promise((resolve, reject) => {
            quickCheckoutApi.setAddress(
                data.addressType, data.addressId
            ).then((result) => {
                commit('SET_BASKET', result.basket);
                resolve(result);
            }).catch((error) => {
                reject(error);
            });
        });
    },
    clear({commit}) {
        return new Promise((resolve, reject) => {
            quickCheckoutApi.clear()
                .then((result) => {
                    commit('RESET');
                    resolve(result);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    order({commit}) {
        return new Promise((resolve, reject) => {
            quickCheckoutApi.order()
                .then((result) => {
                    commit('RESET');
                    resolve(result);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
};

// mutations
const mutations = {
    RESET(state) {
        state.basket = {};
        state.basketItemCount = 0;
    },
    SET_BASKET(state, basket) {
        state.basket = basket;
        state.basketItemCount = basket.basketItemCount;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
