<template>
    <div class="mt-5 d-flex justify-content-center gap-5">
        <div class="d-flex align-items-center">
            <div class="emoji">:(</div>
        </div>
        <div class="d-flex flex-column">
            <div class="header mb-4">{{ $t('pageNotFound.headline') }}</div>
            <div class="text mb-5"><span class="preserveLineBreaks">{{ $t('pageNotFound.text') }}</span></div>
            <div class="d-flex justify-content-center">
                <NdxButton @click="gotoHome">{{ homeData.label }}</NdxButton>
            </div>
        </div>
    </div>
</template>

<script>
    import NdxButton from "../../components/library/NdxButton.vue";

    export default {
        name: 'PageNotFound',
        components: {NdxButton},
        data() {
            return {
                homeData: null
            };
        },
        created() {
            this.homeData = this.$store.getters['shop/getWgcByType']('shopHomepage');
        },
        methods: {
            gotoHome() {
                this.$router.push(this.homeData.route);
            }
        }
    };
</script>

<style lang="scss" scoped>
    @import "../../style/variables_ndx";

    .emoji {
        @extend %font-body1;
        font-size: 100px;
        font-weight: bold;
        white-space: nowrap;
    }

    .header {
        @extend %font-h2;
        text-align: center;
    }

    .text {
        @extend %font-body2;
        text-align: center;
    }
</style>
