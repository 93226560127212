<template>
    <div class="ndx-tree-nav-widget">
        <div
            class="rootNode"
            @click="onRootClick"
        >
            <span class="node-text">
                {{ navName }}
            </span>
        </div>
        <div
            class="childNode"
            :class="{active: activeTaskType === 'active'}"
            @click="onNavClick('active')"
        >
            {{ activeLabel }}
        </div>
        <div
            class="childNode"
            :class="{active: activeTaskType === 'completed'}"
            @click="onNavClick('completed')"
        >
            {{ completedLabel }}
        </div>
    </div>
</template>

<script>
    export default {
        name: 'WorkflowNavWidget',
        props: {
            widget: Object
        },
        computed: {
            navName() {
                return this.$store.getters['shop/getWgcByType'](this.widget.config.wgcType).label;
            },
            activeTaskType() {
                const routeParam = this.$route.params.taskType;
                const isValidRoutParam = ['active', 'completed'].includes(routeParam);
                return isValidRoutParam ? routeParam : null;
            },
            isWfInstance() {
                return this.widget.config.wgcType.toLowerCase().includes('instance');
            },
            activeLabel() {
                return this.isWfInstance ?
                    this.$t('label.workflow.workflowInstancesActive') :
                    this.$t('label.workflow.workflowTasksActive');
            },
            completedLabel() {
                return this.isWfInstance ?
                    this.$t('label.workflow.workflowInstancesCompleted') :
                    this.$t('label.workflow.workflowTasksCompleted');
            }
        },
        methods: {
            onRootClick() {
                if (this.isWfInstance) {
                    this.$router.push({name: 'WorkflowsInstances'});
                } else {
                    this.$router.push({name: 'WorkflowsTasks'});
                }
            },
            onNavClick(taskType) {
                let route = {
                    name: this.widget.config.routeName,
                    params: {
                        ...this.$route.params,
                        taskType: taskType
                    }
                };
                this.$router.push(route);
            },
        }
    };
</script>
