<template>
    <NdxPageHeader
        hide-filter
        :nav-name="navName"
        v-model:view-mode="viewMode"
        view-mode-storage-key="budgetsViewMode"
    />

    <div
        class="card-container"
        :class="{['viewmode-' + viewMode]: true}"
    >
        <NdxCategoryCard
            :title="$t('label.budget_categories')"
            card-icon="budgets"
            :variant="viewMode"
            @click="$router.push({name: 'BudgetCategories'})"
        />
        <NdxCategoryCard
            :title="$t('label.budget_products')"
            card-icon="labeling"
            :variant="viewMode"
            @click="$router.push({name: 'BudgetProducts'})"
        />
    </div>
</template>

<script>
    import NdxCategoryCard from "../library/NdxCategoryCard";
    import NdxPageHeader from "../library/NdxPageHeader.vue";

    export default {
        name: 'Budgets',
        components: {NdxPageHeader, NdxCategoryCard},
        data() {
            return {
                viewMode: 'card'
            };
        },
        computed: {
            navName() {
                const wgc = this.$store.getters['shop/getWgcByType']('budgets');
                return wgc ? wgc.label : this.$t('label.budgets');
            }
        }
    };
</script>

<style lang="scss" scoped>
    @import "~bootstrap/scss/bootstrap-utilities";

    .card-container.viewmode-list {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
    }

    .card-container.viewmode-card {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
        gap: 16px;
    }

    @include media-breakpoint-up(md) {
        .card-container.viewmode-card {
            grid-template-columns: repeat(auto-fill, minmax(222px, 1fr));
        }
    }
</style>
