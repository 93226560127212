<template>
    <Group :group="group" is-summary>
        <template #sub-header>
            <div class="list-key-table">
                <template v-if="showDelivery">
                    <div class="listKey">{{ $t('label.product_deliveryOptions') }}:</div>
                    <div class="listValue">{{ deliveryInfo }}</div>
                </template>

                <div class="listKey">{{ $t('label.deliveryAddress') }}:</div>
                <div class="listValue">{{ formatAddress(group.deliveryAddress) }}</div>
            </div>
        </template>
        <template #group-items>
            <GroupItem
                v-for="item in items"
                :key="group.groupHash + '_' + item.orderItemId"
                :item="item"
                :bundle-path="getBundlePath(item)"
                is-summary
                in-tree
            />
        </template>
    </Group>
</template>

<script>
    import Group from "./parts/Group";
    import { AddressFormatter, PriceFormatter } from "../../../plugins/formatter";
    import GroupItem from "./parts/GroupItem";
    import { mapGetters } from "vuex";

    export default {
        name: "BasketSummaryGroup",
        components: {GroupItem, Group},
        props: {
            group: {
                type: Object,
                required: true
            }
        },
        computed: {
            ...mapGetters('shop', {
                currency: 'currency'
            }),
            showDelivery() {
                return false;
            },
            deliveryInfo() {
                const deliveryOption = this.group.deliveryOption;
                let infoText = [];

                if (deliveryOption.priceTag !== null) {
                    if (deliveryOption.deliveryTimeTag.length) {
                        infoText.push(deliveryOption.deliveryTimeTag);
                    }
                } else if (deliveryOption.minDeliveryTime || deliveryOption.maxDeliveryTime) {
                    if (deliveryOption.minDeliveryTime === deliveryOption.maxDeliveryTime) {
                        infoText.push(
                            deliveryOption.minDeliveryTime + ' ' +
                            this.$tc('units.workingday', deliveryOption.minDeliveryTime)
                        );
                    } else {
                        infoText.push(
                            deliveryOption.minDeliveryTime + ' - ' +
                            deliveryOption.maxDeliveryTime + ' ' +
                            this.$tc('units.workingday', deliveryOption.maxDeliveryTime)
                        );
                    }
                }

                if (deliveryOption.freeShippingValue !== null) {
                    infoText.push(this.$t('label.freeShippingValue', {
                        value: PriceFormatter(deliveryOption.freeShippingValue, this.currency, this)
                    }));
                }

                const info = infoText.length > 0 ? ' (' + infoText.join(', ') + ')' : '';

                return deliveryOption.name + info;
            },
            items() {
                return this.group.items.filter(item => item.quantity > 0);
            }
        },
        methods: {
            getBundlePath(item) {
                return this.$store.getters['bundles/getPathFromOrderItemId'](item.orderItemId);
            },
            formatAddress(addressArr) {
                return AddressFormatter(addressArr, this, ', ');
            }
        }
    };
</script>
